import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// step 1
export default function Post(props) {
  console.log("stock add");
  const $ = window.$;
  const initialized = useRef(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  const currency = aim.useGetCurrency();
  const business = aim.useGetBusiness();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect StockAdd");
      $("#supplier_name").keyup(function () {
        const supplier_name = $(this).val();
        const gql = `query MyQuery {
          autoSuppliers(name_Icontains: "${supplier_name}") {
            edges {
              node {
                name
                phone
                address
              }
            }
          }
        }`;
        $("#supplier_name").devbridgeAutocomplete({
          serviceUrl: `https://landscape.aimsarik.com/gql/`,
          type: "POST",
          ajaxSettings: {
            headers: {
              // jwt token
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            contentType: "application/json",
            data: JSON.stringify({ query: gql }),
          },
          transformResult: function (response) {
            // console.log(response);
            // convert response to json object
            response = JSON.parse(response);
            return {
              suggestions: $.map(
                response.data.autoSuppliers.edges,
                function (dataItem) {
                  return {
                    value: dataItem.node.name,
                    phone: dataItem.node.phone,
                    address: dataItem.node.address,
                  };
                }
              ),
            };
          },
          onSelect: function (suggestion) {
            $("#supplier_name").val(suggestion.value);
            $("#supplier_phone").val(suggestion.phone);
            $("#supplier_address").val(suggestion.address);
          },
        });
        $(this).focus();
      });
      const stock_auto = () => {
        console.log("stock_auto");
        $("table").on("keyup", ".type", function () {
          const supplier_name = $("#supplier_name").val();
          const type = $(this).val();
          const gql = `query MyQuery {
            autoSuppliers(name_Icontains: "${supplier_name}") {
              edges {
                node {
                  categorySet(name_Icontains: "${type}") {
                    edges {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
          }`;
          $(".type").devbridgeAutocomplete({
            serviceUrl: `https://landscape.aimsarik.com/gql/`,
            type: "POST",
            ajaxSettings: {
              headers: {
                // jwt token
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
              contentType: "application/json",
              data: JSON.stringify({ query: gql }),
            },
            transformResult: function (response) {
              // convert response to json object
              response = JSON.parse(response);
              console.log(response);
              if (response.data.autoSuppliers.edges.length > 0)
                return {
                  suggestions: $.map(
                    response.data.autoSuppliers.edges[0].node.categorySet.edges,
                    function (dataItem) {
                      return {
                        value: dataItem.node.name,
                      };
                    }
                  ),
                };
              else return { suggestions: [] };
            },
            onSelect: function (suggestion) {
              $(this).val(suggestion.value);
            },
          });
          $(this).focus();
        });
        $("table").on("keyup", ".title", function () {
          const type = $(".type").val();
          const item = $(this).val();
          const gql = `query MyQuery {
            autoCategories(name_Icontains: "${type}") {
              edges {
                node {
                  name
                  productSet(name_Icontains: "${item}") {
                    edges {
                      node {
                        name
                        code
                        location
                        unitBox
                        uom
                        quantity
                        price
                        specialPrice
                        currency {
                          nameKh
                        }
                      }
                    }
                  }
                }
              }
            }
          }`;
          $(".title").devbridgeAutocomplete({
            serviceUrl: `https://landscape.aimsarik.com/gql/`,
            type: "POST",
            ajaxSettings: {
              headers: {
                // jwt token
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
              contentType: "application/json",
              data: JSON.stringify({ query: gql }),
            },
            transformResult: function (response) {
              // convert response to json object
              response = JSON.parse(response);
              // console.log(response);
              if (response.data.autoCategories.edges.length > 0)
                return {
                  suggestions: $.map(
                    response.data.autoCategories.edges[0].node.productSet.edges,
                    function (dataItem) {
                      return {
                        value: dataItem.node.name,
                        code: dataItem.node.code,
                        location: dataItem.node.location,
                        box: dataItem.node.unitBox,
                        uom: dataItem.node.uom,
                        quantity: dataItem.node.quantity,
                        price: dataItem.node.price,
                        specialPrice: dataItem.node.specialPrice,
                        currency: dataItem.node.currency.nameKh,
                      };
                    }
                  ),
                };
              else return { suggestions: [] };
            },
            onSelect: function (suggestion) {
              $(this).val(suggestion.value);
              $(this).parent().parent().find(".code").val(suggestion.code);
              $(this)
                .parent()
                .parent()
                .find(".location")
                .val(suggestion.location);
              $(this).parent().parent().find(".box").val(suggestion.box);
              $(this).parent().parent().find(".uom").val(suggestion.uom);
              $(this).parent().parent().find(".qty").val(suggestion.quantity);
              $(this).parent().parent().find(".price").val(suggestion.price);
              $(this)
                .parent()
                .parent()
                .find(".specialPrice")
                .val(suggestion.specialPrice);
              $(this)
                .parent()
                .parent()
                .find(".currency option")
                .each(function () {
                  if ($(this).text() === suggestion.currency) {
                    $(this).attr("selected", "selected");
                  }
                });
            },
          });
          $(this).focus();
        });
      };
      stock_auto();

      if (business) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        business.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      let currencyOptions = "";
      if (currency) {
        currency.forEach((element) => {
          // base 64 decode id
          const id = atob(element.node.id);
          // get id
          const id_get = id.split(":")[1];
          if (element.node.isDefault) {
            currencyOptions += `<option value="${id_get}" selected>${element.node.nameKh}</option>`;
          } else {
            currencyOptions += `<option value="${id_get}">${element.node.nameKh}</option>`;
          }
        });
      }
      // stock
      const delete_row = ($this) => {
        withReactContent(Swal)
          .fire({
            icon: "question",
            title: "ការជូនដំណឹង",
            text: "តើអ្នកពិតជាចង់លុបវានេះមែនទេ?",
            showCancelButton: true,
            confirmButtonText: "យល់ព្រម",
            cancelButtonText: `ទេ`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (result.isConfirmed) {
                $this.parents("tr").remove();
              }
            }
          });
      };
      const delete_group = ($this) => {
        withReactContent(Swal)
          .fire({
            icon: "question",
            title: "ការជូនដំណឹង",
            text: "តើអ្នកពិតជាចង់លុបវានេះមែនទេ?",
            showCancelButton: true,
            confirmButtonText: "យល់ព្រម",
            cancelButtonText: `ទេ`,
          })
          .then((result) => {
            var group_type = $this.attr("id");
            $(`.${group_type}`).remove();
          });
      };
      let limit = 15,
        countItem = 0,
        countType = 0;
      $(`#addCate`).click(function () {
        let counter = $(".addItem").length + 1;
        if (counter === limit) {
          alert(`អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម ${counter}`);
        } else {
          const tbody_1 = `<tbody class="group_type${counter}">
            <tr>
              <td colspan="10" align="center">
                <input type="button" value="បន្ថែមផលិតផល" class="addItem btn btn-primary" id="addItem${counter}" data-type="${counter}">
              </td>
            </tr>
          </tbody>`;
          const tbody_2 = `<tbody class="group_type${counter}">
            <tr>
              <td colspan="1" align="right">
                <span style="color:red;cursor: pointer;" class="del_type" id="group_type${counter}">X</span>
              </td>
              <td colspan="9" align="center">
                <div class="input-group" style="width: 100%; margin: 0 auto">
                  <div class="input-group-prepend">
                    <span class="input-group-text">ប្រភេទ</span>
                  </div>
                  <input type="text" name="type${counter}" class="type form-control" required autocomplete="off">
                </div>
              </td>
            </tr>
          </tbody>`;
          const tbody_3 = `<tbody class="type${counter} group_type${counter}">
          </tbody>`;
          $(tbody_3).prependTo(`#report #addCategory`);
          $(tbody_2).prependTo(`#report #addCategory`);
          $(tbody_1).prependTo(`#report #addCategory`);
          $(`#report #addItem${counter}`).click();
          counter++;
        }
      });
      $(`#report table`).on("click", ".addItem", function () {
        countType = $(this).attr("data-type");
        countItem = $(`.type${countType}`).find(".itemAdd").length + 1;
        if (countItem === 30) {
          // alert("អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម 30");
          withReactContent(Swal).fire({
            icon: "warning",
            title: "ការព្រមាន",
            text: `អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម 30`,
          });
        } else {
          var itemAdd = `<tr class="itemAdd">
            <td data-title="លុប">
              <span style="color:red;cursor: pointer;" class="del_X">X</span>
            </td>
            <td data-title="ឈ្មោះផលិតផល">
              <input type="text" class="form-control title" name="type${countType}[title[]]" autocomplete="off" required>
            </td>
            <td data-title="លេខកូដ">
              <input type="text" class="form-control code" name="type${countType}[code[]]" autocomplete="off">
            </td>
            <td data-title="ទីតាំង">
              <input type="text" class="form-control location" name="type${countType}[location[]]" autocomplete="off">
            </td>
            <td data-title="Pcs/Box">
              <input type="text" class="form-control box number" name="type${countType}[box[]]" autocomplete="off">
            </td>
            <td data-title="ឯកតា">
              <input type="text" class="form-control uom" name="type${countType}[uom[]]" autocomplete="off">
            </td>
            <td data-title="បរិមាណ">
              <input type="text" class="form-control number operator qty" name="type${countType}[qty[]]" value="0" autocomplete="off">
            </td>
            <td data-title="តម្លៃលក់">
              <input type="text" class="form-control number operator price" name="type${countType}[price[]]" value="0" autocomplete="off">
            </td>
            <td data-title="ពិសេស">
              <input type="text" class="form-control number operator specialPrice" name="type${countType}[specialPrice[]]" value="0" autocomplete="off">
            </td>
            <td data-title="រូបិយប័ណ្ណ">
              <select name="type${countType}[currency[]]" class="form-control currency"></select>
            </td>
          </tr>`;
          $(itemAdd).prependTo($(`.type${countType}`));
          // add currency to select option
          $(`.type${countType} .currency`).html(currencyOptions);
        }
      });
      $(`#addCate`).click();
      $("table").on("click", ".del_X", function () {
        delete_row($(this));
      });
      $("table").on("click", ".del_type", function () {
        delete_group($(this));
      });
      // focus input
      $(".itemAdd").on("focus", ".form-control", function () {
        $(this).parents(".itemAdd").find(".form-control").css("width", "100%");
        $(this).css("width", "220px");
      });
    }
  }, [currency, business, $]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បន្ថែមផលិតផល</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  {/* business */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាជីវកម្ម</span>
                    </div>
                    <select
                      id="business"
                      name="business"
                      className="form-control"
                      required
                    ></select>
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អ្នកផ្គត់ផ្គង់</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[name]"
                      id="supplier_name"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ទូរស័ព្ទ</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[phone]"
                      id="supplier_phone"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាសយដ្ឋាន</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[address]"
                      id="supplier_address"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-12 text-center mb-2">
                  <input
                    className="btn btn-primary"
                    type="button"
                    defaultValue="បន្ថែមក្រុម"
                    id="addCate"
                  />
                </div>

                <div className="row mt-4">
                  <div id="no-more-tables" className="w-100">
                    <div className="card border-primary">
                      <h3 className="card-header bg-primary text-white text-center">
                        <span>បន្ថែមផលិតផល</span>
                      </h3>
                      <div className="card-body mt-2 row">
                        <table className="table table-hover" id="addCategory">
                          <thead>
                            <tr>
                              <th>លុប</th>
                              <th>ឈ្មោះផលិតផល</th>
                              <th>លេខកូដ</th>
                              <th>ទីតាំង</th>
                              <th>Pcs/Box</th>
                              <th>ឯកតា</th>
                              <th>បរិមាណ</th>
                              <th>តម្លៃលក់</th>
                              <th>ពិសេស</th>
                              <th>រូបិយប័ណ្ណ</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/stock")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
