import React, { useEffect } from "react";
import {
  // createBrowserRouter,
  // RouterProvider,
  Routes,
  Route,
  useNavigate,
  // Link,
} from "react-router-dom";

import SignIn from "./components/SignIn";
import Nevbar from "./components/Nevbar";
import Sidebar from "./components/Sidebar";
// pages
import Page404 from "./pages/Page404";
import Home from "./pages/Home";
// sales
import SaleTable from "./pages/Sale/SaleTable";
import SaleOrder from "./pages/Sale/SaleOrder";
// stock
import Stock from "./pages/Stock/Stock";
import StockOut from "./pages/Stock/StockOut";
// settings
import Currency from "./pages/Settings/Currency";
import COA from "./pages/Settings/COA";
import FinanPreparant from "./pages/Settings/FinanPreparant";
import Company from "./pages/Settings/Company";
import ComBusiness from "./pages/Settings/ComBusiness";
import Telegram from "./pages/Settings/Telegram";
import Table from "./pages/Settings/Table";
import SaleInformation from "./pages/Settings/SaleInformation";
import Users from "./pages/Settings/Users";
// actions post
import Post from "./postData/Post";

const checkAuth = () => {
  const checkToken = localStorage.getItem("token") ? true : false;
  const checkUser = localStorage.getItem("user") ? true : false;
  if (checkToken === false || checkUser === false) {
    return false;
  } else {
    return true;
  }
};

function App() {
  // console.log("App");
  const navigate = useNavigate();
  useEffect(() => {
    if (checkAuth() === false) {
      navigate("/sign_in");
    }
  }, [navigate]);

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("business");
    // navigate("/sign_in");
  };

  return (
    <>
      {checkAuth() && (
        <>
          <Nevbar />
          <Sidebar signOut={signOut} />
          <Routes>
            <Route path="*" element={<Page404 />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/sale_table" element={<SaleTable />} />
            <Route path="/sale_order" element={<SaleOrder />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/stock_out" element={<StockOut />} />
            <Route path="/currency" element={<Currency />} />
            <Route path="/coa" element={<COA />} />
            <Route path="/finan_preparant" element={<FinanPreparant />} />
            <Route path="/company" element={<Company />} />
            <Route path="/business" element={<ComBusiness />} />
            <Route path="/telegram" element={<Telegram />} />
            <Route path="/table" element={<Table />} />
            <Route path="/sale_information" element={<SaleInformation />} />
            <Route path="/users" element={<Users />} />
            <Route path="/post" element={<Post />}>
              <Route path=":id" element={<Post />} />
            </Route>
          </Routes>
        </>
      )}
      {!checkAuth() && (
        <>
          <Routes>
            <Route path="/" element=<div></div> />
            <Route exact path="/sign_in" element={<SignIn />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
