// step 1
// 1. update side bar
// 2. update the page in App.js
// step 3
import * as aim from "../../js/function.easy7d";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

// step 2
export default function TgInfo() {
  // step 4
  const navigate = useNavigate();
  console.log("business");
  const page_name = "business";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  // step 5
  const thead = aim.datatableHeadFoot(
    ["លរ", "ក្រុមហ៊ុន", "អាជីវកម្ម", "សកម្មភាព"],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    console.log("useEffect business");
    aim.loadData(
      {
        tbId: tbId,
        page_name: page_name,
        load_page: "allCompanies",
        gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                logo
                name
                branch
                businessSet {
                  edges {
                    node {
                      id
                      business
                    }
                  }
                }
              }
            }
        }`,
      },
      {
        page_name: page_name,
        function_load: `${page_name}_datatable`,
        sumAmount: sumAmount,
        order_false: [2, 3],
        width_column: {
          1: "100px",
        },
        columnHidden: [],
        orderColumn: ["id", "name", "", "", ""],
        searching: ["name_Icontains"],
        /* otherOption: {
          status: true,
        }, */
        // function
        data_execute: (result, load_page) => {
          /* function action_group(action) {
            var data = `<div class="btn-group">
              <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  សកម្មភាព
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                  ${action}
              </div>
            </div>`;
            return data;
          } */
          // step 6
          // console.log("result:", result, "load_page:", load_page);
          // console.log("page_name", page_name);
          const data_tb_row = [];
          result.recordsTotal = result.data[load_page].totalCount;
          result.recordsFiltered = result.data[load_page].totalCount;
          // console.log(result.recordsTotal);
          // console.log("result:", result.data[load_page].edges);
          // forEach
          let number = 1;
          result.data[load_page].edges.forEach((v) => {
            // check img
            /* let img = "";
            if (v.node.logo === "") {
              // PUBLIC folder in react public
              img = `<img src="/dist/img/noimage.jpg" class="img-thumbnail" width="100px">`;
            } else {
              img = `<img src="https://landscape.aimsarik.com/media/${v.node.logo}" class="img-thumbnail" width="100px">`;
            } */
            // loop businessSet edges node business
            v.node.businessSet.edges.forEach((x) => {
              const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${x.node.id}','page':'${page_name}'}"></i>`;
              var tb_row = [
                `${number++}`,
                `ឈ្មោះក្រុមហ៊ុន: ${v.node.name}<br>
                ឈ្មោះសាខា: ${v.node.branch}`,
                `${x.node.business}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
          });
          return data_tb_row;
        },
      }
    );
    document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
      const el = e.target;
      if (el.classList.contains("editData")) {
        const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
        const { id, page } = data_edit;
        navigate(`/post/${page}?action=edit&id=${id}`);
      }
    });
  }, [page_name, tbId, sumAmount, navigate]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
