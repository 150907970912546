// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import ComBusinessAdd from "./ComBusinessAdd";
import ComBusinessEdit from "./ComBusinessEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

// step 2
export default function Post(props) {
  console.log("business post");
  // step 2
  const navigate = useNavigate();
  const action = props.searchParams.get("action");

  // step 3
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // edit data
      const page = "postBusiness";

      const callback = (data) => {
        // console.log(data.errors);
        data.page = page;
        // edit data
        let action = "អាជីវកម្ម";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }
        // edit data
        const companySelect = document.getElementById("company");
        const companyText =
          companySelect.options[companySelect.selectedIndex].text;

        let dataMessage = `
<b>${action}</b>

<b>អាជីវកម្ម:</b> ${d.business}
<b>ក្រុមហ៊ុន:</b> ${companyText}`;

        dataMessage = dataMessage.replace(/\n/g, "\\n");
        // edit data Telegram Function
        const functionName = "Business";
        const gql = `
          mutation MyMutation {
            sendTelegramMessage(
              text: "${dataMessage}"
              functionName: "${functionName}"
            ) {
              success
            }
          }
        `;
        // edit data
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };

      const gql = `
        mutation MyMutation {
          ${page}(
            ${getData.data.data}
            business: "${d.business}"
            company: "${d.company}"
          ) {
            success
          }
        }
      `;
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3

  if (action === "add") {
    return <ComBusinessAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <ComBusinessEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
