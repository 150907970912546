import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useEffect } from "react";
// step 1
export default function Post(props) {
  // step 2
  // console.log("props:", props);
  const currency = aim.useGetCurrency();
  const business = aim.useGetBusiness();
  useEffect(() => {
    if (currency) {
      const currencySelect = document.getElementById("currency");
      currencySelect.innerHTML = "";
      // add option to select
      // currencySelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
      currency.forEach((element) => {
        // base 64 decode id
        const id = atob(element.node.id);
        // get id
        const id_get = id.split(":")[1];

        const option = document.createElement("option");
        if (element.node.isDefault) {
          option.selected = true;
        }
        option.value = element.node.id;
        option.text = element.node.nameKh;
        // data-id for update
        option.setAttribute("data-id", id_get);
        currencySelect.appendChild(option);
      });
    }
    if (business) {
      const businessSelect = document.getElementById("business");
      businessSelect.innerHTML = "";
      // add option to select
      businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
      business.forEach((element) => {
        // const option = document.createElement("option");
        // option.value = element.node.id;
        // option.text = element.node.name;
        element.node.businessSet.edges.forEach((v) => {
          const option = document.createElement("option");
          option.value = v.node.id;
          option.text = v.node.business + " - " + element.node.name;
          businessSelect.appendChild(option);
        });
      });
    }
  }, [currency, business]);
  // step 2
  // step 3
  const handleChange = (e) => {
    e.preventDefault();
    let categoryVal = document.getElementById("category").value;
    if (categoryVal === "ទ្រព្យ") {
      categoryVal = 1;
    } else if (categoryVal === "បំណុល") {
      categoryVal = 2;
    } else if (categoryVal === "ដើមទុន") {
      categoryVal = 3;
    } else if (categoryVal === "ចំណូល") {
      categoryVal = 4;
    } else if (categoryVal === "ចំណាយ") {
      categoryVal = 5;
    } else {
      withReactContent(Swal).fire({
        icon: "info",
        title: "ជ្រើសរើស",
        html: `សូមបញ្ហាថា ថ្នាក់ ត្រូវបានជ្រើសរើស`,
        confirmButtonText: "យល់ព្រម",
      });
    }
    const currencyDataId = document
      .getElementById("currency")
      .selectedOptions[0].getAttribute("data-id");
    // update input value to account_no
    const account_no = document.getElementById("account_no");
    account_no.value = `${categoryVal}${currencyDataId}`;
  };
  // step 3
  // step 4 setup database on server and create schema
  // step 1
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "createCoa", data: null });
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ប្លង់គណនី</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <select
                    id="business"
                    name="business"
                    className="form-control"
                    required
                  ></select>
                </div>
                {/* type */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="type"
                    id="type"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* account_name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះ​គណនី</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="account_name"
                    id="account_name"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* category */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ថ្នាក់</span>
                  </div>
                  <select
                    id="category"
                    name="category"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">ជ្រើសរើស</option>
                    <option value="ទ្រព្យ">ទ្រព្យ</option>
                    <option value="បំណុល">បំណុល</option>
                    <option value="ដើមទុន">ដើមទុន</option>
                    <option value="ចំណូល">ចំណូល</option>
                    <option value="ចំណាយ">ចំណាយ</option>
                  </select>
                  <select
                    id="currency"
                    name="currency"
                    className="form-control"
                    onChange={handleChange}
                  ></select>
                </div>
                {/* account_no */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខគណនី</span>
                  </div>
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    className="form-control"
                    minLength={5}
                    name="account_no"
                    id="account_no"
                    defaultValue={11}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={props.props.clearForm}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
