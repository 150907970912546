// step 1
// 1. update side bar
// 2. update the page in App.js

// step 3
import * as aim from "../../js/function.easy7d";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// step 2
export default function Table() {
  // step 4
  console.log("Table.js");
  const page_name = "table";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  // step 5
  const thead = aim.datatableHeadFoot(
    ["លរ", "ព័ត៌មានតុ", "ស្ថានភាព", "សកម្មភាព"],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    aim.loadData(
      {
        tbId: tbId,
        page_name: page_name,
        load_page: "allTable",
        gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                name
                number
                tableType
              }
            }
        }`,
      },
      {
        page_name: page_name,
        function_load: `${page_name}_datatable`,
        sumAmount: sumAmount,
        order_false: [2, 3],
        width_column: {
          1: "100px",
        },
        columnHidden: [],
        orderColumn: ["id", "name", "", "", ""],
        searching: ["name_Icontains"],
        /* otherOption: {
          status: true,
        }, */
        // function
        data_execute: (result, load_page) => {
          // step 7
          // console.log("result:", result, "load_page:", load_page);
          const data_tb_row = [];
          result.recordsTotal = result.data[load_page].totalCount;
          result.recordsFiltered = result.data[load_page].totalCount;
          // console.log(result.recordsTotal);
          // console.log("result:", result.data[load_page].edges);
          // forEach
          let number = 1;
          result.data[load_page].edges.forEach((v) => {
            // base 64 decode id
            // const id = atob(v.node.id);
            // get id
            // const id_get = id.split(":")[1];

            /* let status = "";
            let status_val = 0;
            if (v.node.status === true) {
              status = ` checked`;
              status_val = 1;
            } else {
              status = ``;
              status_val = 0;
            }
            let checkbox_status = `<label class="switch">
              <input class="status_table updateStatus" type="checkbox" ${status} data-id="${v.node.id}" value="${status_val}">
              <span class="slider round"></span>
            </label>`; */
            const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
            var tb_row = [
              `${number++}`,
              `${v.node.name} - ${v.node.number}<br>
              ប្រភេទ - ${v.node.tableType}`,
              ``,
              `${action}`,
            ];
            data_tb_row.push(tb_row);
          });
          return data_tb_row;
        },
      }
    );
  }, [page_name, tbId, sumAmount]);
  // step 8
  const navigate = useNavigate();
  const statusHandle = (id, data, status) => {
    try {
      const gql = `mutation {
        updateCoa(
          id: "${id}"
          status: ${data}
        ) {
          coa {
            name
          }
        }
      }`;
      const callback = (data) => {
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 2000,
          });
          // reload page when timer end
          if (status === "isDefault") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // document.queryselector .editData
    document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
      const el = e.target;
      if (el.classList.contains("editData")) {
        const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
        const { id, page } = data_edit;
        navigate(`/post/${page}?action=edit&id=${id}`);
      } else if (el.classList.contains("updateStatus")) {
        const id = el.dataset.id;
        const status = el.checked;
        statusHandle(id, status, "status");
      }
    });
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* step 6 */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
