export default function Nevbar() {
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <i
            className="nav-link fas fa-bars"
            data-widget="pushmenu"
            href="#"
            role="button"
          />
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="/" className="nav-link">
            Home
          </a>
        </li>
      </ul>
    </nav>
  );
}
