import { useNavigate } from "react-router-dom";
// step 1
export default function Post(props) {
  console.log("company add");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានក្រុមហ៊ុន</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* company name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះក្រុមហ៊ុន</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* branch */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">សាខា</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="branch"
                    id="branch"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* logo */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">និមិត្តសញ្ញា</span>
                  </div>
                  <input
                    className="form-control"
                    type="file"
                    name="logo"
                    id="logo"
                    autoComplete="off"
                  />
                </div>
                {/* company phone */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខទូរសព្ទ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* company email */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អ៊ីមែល</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                  />
                </div>
                {/* company address */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាសយដ្ឋាន</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* conditions */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លក្ខខណ្ឌ</span>
                  </div>
                  {/* texearea */}
                  <textarea
                    className="form-control"
                    name="conditions"
                    id="conditions"
                    rows="5"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/company")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
