// step 3
import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
// step 1
export default function Post(props) {
  // step 2
  // console.log("props:", props);
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  // step 4
  const businessRef = useRef();
  const companyRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    // get data
    const query = `
      query {
        business(id: "${id}") {
          id
          business
          Company {
            id
            name
          }
        }
        allCompanies {
          edges {
            node {
              id
              name
              businessSet {
                edges {
                  node {
                    id
                    business
                  }
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      id,
    };
    const callback = (data) => {
      const d = data.data.business;
      // console.log("data:", d);
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
          confirmButtonText: "យល់ព្រម",
        });
        navigate("/post/coa?action=add");
      } else {
        // console.log("data:", data);
        const business = data.data.allCompanies.edges;
        // console.log("business:", business);
        const companySelect = document.getElementById("company");
        companySelect.innerHTML = "";
        // add option to select
        // companySelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        business.forEach((element) => {
          const option = document.createElement("option");
          option.value = element.node.id;
          option.text = element.node.name;
          companySelect.appendChild(option);
        });
        // console.log("data:", d);
        businessRef.current.value = d.business;
        /* const companySelect = document.getElementById("company"); */
        companySelect.value = d.Company.id;
      }
    };
    aim.jquery_free(query, variables, callback);
  }, [id, navigate]);
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានអាជីវកម្ម</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="business"
                    id="business"
                    autoComplete="off"
                    required
                    ref={businessRef}
                  />
                </div>
                {/* company */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ក្រុមហ៊ុន</span>
                  </div>
                  <select
                    className="form-control"
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="off"
                    required
                    ref={companyRef}
                  ></select>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/business")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
