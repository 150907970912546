// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import CompanyAdd from "./CompanyAdd";
import CompanyEdit from "./CompanyEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

// step 2
export default function Post(props) {
  console.log("company post");
  // step 2
  const navigate = useNavigate();
  const action = props.searchParams.get("action");

  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // edit data
      const page = "postCompany";
      const callback = (data) => {
        // console.log(data.errors);
        data.page = page;
        // edit data
        let action = "ក្រុមហ៊ុន";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }

        let dataMessage = `
<b>${action}</b>

<b>ក្រុមហ៊ុនឈ្មោះ</b> ${d.name}
<b>សាខា</b> ${d.branch}
<b>អ៊ីម៉ែល</b> ${d.email}
<b>ទូរស័ព្ទ</b> ${d.phone}
<b>អាសយដ្ឋាន</b> ${d.address}
<b>លក្ខខណ្ឌ</b> ${d.conditions}`;
        // convert to string with break line
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        const functionName = "Company";
        const gql = `
                  mutation MyMutation {
                    sendTelegramMessage(
                      text: "${dataMessage}"
                      functionName: "${functionName}"
                    ) {
                      success
                    }
                  }
                `;
        // console.log(gql);
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };

      // check logo ifle is empty
      if (document.getElementById("logo").files.length === 0) {
        const gql = `
          mutation MyMutation {
            ${page}(
              ${data.data}
              name: "${d.name}"
              branch: "${d.branch}"
              email: "${d.email}"
              phone: "${d.phone}"
              address: "${d.address}"
              conditions: "${d.conditions}"
            ) {
              success
            }
          }
        `;
        // console.log(gql);
        aim.jquery_free(gql, {}, callback);
      } else {
        // upload file
        const logo = document.getElementById("logo").files[0];
        const formData = new FormData();
        formData.append("logo", logo);

        const query = `
          mutation MyMutation($logo: Upload!) {
            ${page}(
              ${data.data}
              logo: $logo
              name: "${d.name}"
              branch: "${d.branch}"
              email: "${d.email}"
              phone: "${d.phone}"
              address: "${d.address}"
              conditions: "${d.conditions}"
            ) {
              success
            }
          }
        `;

        const variables = { logo: null };
        const graphqlRequestBody = {
          operationName: null,
          query: query,
          variables: variables,
        };
        const graphqlRequestBodyString = JSON.stringify(graphqlRequestBody);
        const graphqlFormData = new FormData();
        graphqlFormData.append("operations", graphqlRequestBodyString);
        graphqlFormData.append("map", '{"0": ["variables.logo"]}');
        graphqlFormData.append(0, logo);
        // upload file

        // console.log(graphqlFormData.get("operations"));
        aim.jquery_uploadFile(graphqlFormData, callback);
        // console.log(graphqlFormData);
      }
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3

  if (action === "add") {
    return <CompanyAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <CompanyEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
