import Page404 from "../../pages/Page404";
import UsersAdd from "./UsersAdd";
import UsersEdit from "./UsersEdit";
import { useNavigate } from "react-router-dom";

// for add users
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

export default function Post(props) {
  console.log("users post");
  const navigate = useNavigate();
  const $ = window.$;
  // console.log(props);
  const action = props.searchParams.get("action");
  // handle submit
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);

      const d = getData.dataForm;
      // edit data
      const page = "postAccount";

      if (d.active === "1") {
        d.active = true;
      } else {
        d.active = false;
      }
      const businessList = d["business[]"];
      const phoneList = d["phone[]"];
      const gql = `mutation {
          ${page}(
            ${data.data}
            isActive: ${d.active}
            address: "${d.address}"
            birthday: "${d.birthday}"
            email: "${d.email}"
            firstName: "${d.firstname}"
            gender: "${d.gender}"
            lastName: "${d.lastname}"
            password: "${d.password}"
            phone: ${JSON.stringify(phoneList)}
            position: "${d.position}"
            business: ${JSON.stringify(businessList)}
          ) {
            account {
              phone
              gender
              user {
                username
              }
            }
          }
        }`;
      const callback = (data) => {
        // console.log(data);
        data.page = page;
        // edit data
        let action = "អ្នកប្រើប្រាស់";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }
        // edit data
        let dataMessage = `
<b>${action}</b>

<b>ឈ្មោះរបស់អ្នក:</b> ${d.nickname}
<b>នាមត្រកូល:</b> ${d.lastname}
<b>ឈ្មោះ:</b> ${d.firstname}
<b>ភេទ:</b> ${d.gender}
<b>លេខទូរស័ព្ទ:</b> ${d.phone}
<b>អ៊ីមែល:</b> ${d.email}
<b>តួរនាទីការងារ:</b> ${d.position}
<b>អាសយដ្ឋាន:</b> ${d.address}
<b>ថ្ងៃខែឆ្នាំកំណើត:</b> ${d.birthday}
<b>ក្រុមហ៊ុន:</b> ${businessList}`;
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        // edit data Telegram Function
        const functionName = "Users";
        const gql = `
          mutation MyMutation {
            sendTelegramMessage(
              text: "${dataMessage}"
              functionName: "${functionName}"
            ) {
              success
            }
          }
        `;
        // edit data
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };
  const validate = () => {
    $("#addDataForm").validate({
      rules: {
        nickname: {
          required: true,
          minlength: 2,
        },
        lastname: {
          required: true,
          minlength: 2,
        },
        firstname: {
          required: true,
          minlength: 2,
        },
        password: {
          required: true,
          minlength: 6,
        },
        confirm_password: {
          required: true,
          minlength: 6,
          equalTo: "#password",
        },
        phone: {
          required: true,
          minlength: 9,
        },
        email: {
          required: true,
          email: true,
        },
        gender: {
          required: true,
        },
        position: {
          required: true,
          minlength: 2,
        },
      },
      messages: {
        nickname: {
          required: "សូមបញ្ចូលឈ្មោះអ្នកប្រើប្រាស់របស់អ្នក។",
          minlength: "ឈ្មោះរបស់អ្នកត្រូវតែវែងជាង 2 តួអក្សរ",
        },
        lastname: {
          required: "សូមបញ្ចូលនាមត្រកូលរបស់អ្នក។",
          minlength: "ឈ្មោះរបស់អ្នកត្រូវតែវែងជាង 2 តួអក្សរ",
        },
        firstname: {
          required: "សូមបញ្ចូលឈ្មោះរបស់អ្នក។",
          minlength: "ឈ្មោះរបស់អ្នកត្រូវតែវែងជាង 2 តួអក្សរ",
        },
        password: {
          required: "សូម​បញ្ចូល​ពាក្យ​សម្ងាត់​របស់​អ្នក",
          minlength: "ពាក្យសម្ងាត់របស់អ្នកត្រូវតែវែងជាង 6 តួអក្សរ",
        },
        confirm_password: {
          required: "សូមបញ្ចូលពាក្យសម្ងាត់បញ្ជាក់របស់អ្នក",
          minlength: "ពាក្យសម្ងាត់របស់អ្នកត្រូវតែវែងជាង 6 តួអក្សរ",
          equalTo: "សូមបញ្ចូលពាក្យសម្ងាត់ដូចខាងលើ",
        },
        phone: {
          required: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក។",
          minlength: "លេខទូរស័ព្ទរបស់អ្នកត្រូវតែវែងជាង 9 តួអក្សរ",
        },
        email: {
          required: "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក",
          email: "សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែលត្រឹមត្រូវ",
        },
        gender: "សូមជ្រើសរើសភេទរបស់អ្នក",
        position: {
          required: "សូមបញ្ចូលតួរនាទីការងាររបស់អ្នក",
          minlength: "តួរនាទីការងាររបស់អ្នកត្រូវតែវែងជាង 2 តួអក្សរ",
        },
      },
    });
  };
  const birthday = () => {
    $("#birthday").bootstrapBirthday({
      widget: {
        wrapper: {
          tag: "div",
          class: "row col",
        },
        wrapperYear: {
          use: true,
          tag: "span",
          class: "input-group-addon",
        },
        wrapperMonth: {
          use: true,
          tag: "span",
          class: "input-group-addon",
        },
        wrapperDay: {
          use: true,
          tag: "span",
          class: "input-group-addon",
        },
        selectYear: {
          name: "birthday[year]",
          class: "form-control input-sm",
        },
        selectMonth: {
          name: "birthday[month]",
          class: "form-control input-sm",
        },
        selectDay: {
          name: "birthday[day]",
          class: "form-control input-sm",
        },
      },
    });
  };

  if (action === "add") {
    // multiple props clearForm={clearForm} and handleSubmit={handleSubmit} to UsersAdd
    return <UsersAdd props={{ handleSubmit, validate, birthday }} />;
  } else if (action === "edit") {
    return (
      <UsersEdit
        props={{
          ...props,
          handleSubmit,
          validate,
          birthday,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
