import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Page404 from "../pages/Page404";
import Currency from "./Settings/Currency";
import COA from "./Settings/COA";
import Company from "./Settings/Company";
import Business from "./Settings/ComBusiness";
import Telegram from "./Settings/Telegram";
import Table from "./Settings/Table";
import SaleInformation from "./Settings/SaleInformation";
import Users from "./Settings/Users";
import Stock from "./Stock/Stock";
import StockOut from "./Stock/StockOut";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

export default function Post() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  // console.log(id);
  // split id to get page name
  if (id === "users") {
    return <Users searchParams={searchParams} />;
  } else if (id === "stock") {
    return <Stock searchParams={searchParams} />;
  } else if (id === "stock_out") {
    return <StockOut searchParams={searchParams} />;
  } else if (id === "currency") {
    return <Currency searchParams={searchParams} />;
  } else if (id === "coa") {
    return <COA searchParams={searchParams} />;
  } else if (id === "company") {
    return <Company searchParams={searchParams} />;
  } else if (id === "business") {
    return <Business searchParams={searchParams} />;
  } else if (id === "telegram") {
    return <Telegram searchParams={searchParams} />;
  } else if (id === "table") {
    return <Table searchParams={searchParams} />;
  } else if (id === "sale_information") {
    return <SaleInformation searchParams={searchParams} />;
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            {/* សូមពិនិត្យមើលតំណរបស់អ្នក ហើយព្យាយាមម្តងទៀត។ */}
            <Page404 />
          </div>
        </div>
      </div>
    );
  }
}
