import Page404 from "../../pages/Page404";
import CurrencyAdd from "./CurrencyAdd";
import CurrencyEdit from "./CurrencyEdit";
import { useNavigate } from "react-router-dom";

// for add Currency
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Post(props) {
  const navigate = useNavigate();
  const $ = window.$;
  // console.log(props);
  const action = props.searchParams.get("action");
  // clear form
  const clearForm = () => {
    // console.log("clearForm");
    // document.getElementById("addDataForm").reset();
    navigate("/currency");
  };
  // handle submit
  const handleSubmit = (data) => {
    try {
      // e.preventDefault();
      const dataForm = {};
      $("#addDataForm")
        .serializeArray()
        .map(function (x) {
          if (x.name.indexOf("[]") !== -1) {
            // check dataForm[x.name] is array
            if (Array.isArray(dataForm[x.name])) {
              dataForm[x.name].push(x.value);
            } else {
              dataForm[x.name] = [x.value];
            }
          } else {
            dataForm[x.name] = x.value;
          }
          return true;
        });
      // console.log("dataForm:", dataForm);
      const d = dataForm;
      /* if (d.active === "1") {
        d.active = true;
      } else {
        d.active = false;
      } */
      // console.log(data);
      if (data.page !== "updateCurrency") {
        data.data = "";
      } else {
        data.data = `id: "${data.data}"`;
      }
      const gql = `mutation {
          ${data.page}(
            ${data.data}
            flag: "${d.flag}"
            nameKh: "${d.name}"
            shortName: "${d.short_name}"
            order: ${d.order}
            shortcutKey: "${d.shortcut_key}"
            round: ${d.round}
            rateBuy: ${d.rateBuy}
            rateFixed: ${d.rateFixed}
            rateSale: ${d.rateSale}
          ) {
            currency {
              nameKh
              shortName
              shortcutKey
              order
              round
            }
          }
        }`;
      const callback = (data) => {
        // console.log(data);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 3500,
          });
          clearForm();
        }
      };
      // console.log(gql);
      // aim.jquery_mutation(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };

  if (action === "add") {
    // multiple props clearForm={clearForm} and handleSubmit={handleSubmit} to CurrencyAdd
    return <CurrencyAdd props={{ clearForm, handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <CurrencyEdit
        props={{
          ...props,
          clearForm,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
