import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";

export default function Users() {
  console.log("users");
  const navigate = useNavigate();
  const page_name = "users";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  const thead = aim.datatableHeadFoot(
    [
      "លរ",
      "ឈ្មោះអ្នកប្រើ",
      "ភេទ",
      "ទូរស័ព្ទ",
      "អាជីវកម្ម",
      "ស្ថានភាព",
      "សកម្មភាព",
    ],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    console.log("useEffect users");
    aim.loadData(
      {
        // columns: columnLoad,
        tbId: tbId,
        page_name: page_name,
        load_page: "allAccounts",
        gql: `{
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
          edgeCount
          edges {
            cursor
            node {        
              user {
                firstName
                lastName
                username
                isActive
              }
              id
              gender
              phone {
                id
                number
              }
              business {
                edges {
                  node {
                    id
                    business
                  }
                }
              }
            }
          }
        }`,
      },
      {
        // link: link,
        page_name: page_name,
        function_load: `${page_name}_datatable`,
        sumAmount: sumAmount,
        order_false: [2, 3, 4, 5, 6],
        width_column: {
          1: "100px",
        },
        columnHidden: [],
        orderColumn: ["id", "user__username", "", "", "", "", ""],
        searching: ["user_Username_Icontains", "phone_Icontains"],
        // function
        data_execute: (result, load_page) => {
          // console.log("result:", result, "load_page:", load_page);
          const data_tb_row = [];
          result.recordsTotal = result.data[load_page].totalCount;
          result.recordsFiltered = result.data[load_page].totalCount;
          // console.log(result.recordsTotal);
          // forEach
          let number = 1;
          result.data[load_page].edges.forEach((v) => {
            // base 64 decode id
            // const id = atob(v.node.id);
            // get id
            // const id_get = id.split(":")[1];

            let status = "";
            if (v.node.user.isActive === true) {
              status = '<span class="text-primary">Active</span>';
            } else {
              status = '<span class="text-danger">Inactive</span>';
            }
            const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
            // loop business for user
            let business = "";
            v.node.business.edges.forEach((v) => {
              // add comma if business is not empty
              if (business !== "") {
                business += ", ";
              }
              business += v.node.business;
            });
            let phone = "";
            v.node.phone.forEach((v) => {
              if (phone !== "") {
                phone += ", ";
              }
              phone += v.number;
            });

            var tb_row = [
              `${number++}`,
              `${v.node.user.firstName} ${v.node.user.lastName}`,
              `${v.node.gender}`,
              `${phone}`,
              `${business}`,
              `${status}`,
              `${action}`,
            ];
            data_tb_row.push(tb_row);
          });
          return data_tb_row;
        },
      }
    );
    // document.queryselector .editData
    document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
      const el = e.target;
      if (el.classList.contains("editData")) {
        const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
        const { id, page } = data_edit;
        navigate(`/post/${page}?action=edit&id=${id}`);
      }
    });
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
