// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import COAAdd from "./COAadd";
import COAEdit from "./COAedit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// step 3
// step 2
export default function Post(props) {
  // step 2
  const navigate = useNavigate();
  const $ = window.$;
  // console.log(props);
  const action = props.searchParams.get("action");
  // step 2
  // step 3
  const clearForm = () => {
    // console.log("clearForm");
    // document.getElementById("addDataForm").reset();
    navigate("/coa");
  };
  const handleSubmit = (data) => {
    try {
      const dataForm = {};
      $("#addDataForm")
        .serializeArray()
        .map(function (x) {
          if (x.name.indexOf("[]") !== -1) {
            if (Array.isArray(dataForm[x.name])) {
              dataForm[x.name].push(x.value);
            } else {
              dataForm[x.name] = [x.value];
            }
          } else {
            dataForm[x.name] = x.value;
          }
          return true;
        });
      // console.log("dataForm:", dataForm);
      const d = dataForm;
      if (data.page !== "updateCoa") {
        data.data = "";
      } else {
        data.data = `id: "${data.data}"`;
      }
      const gql = `mutation {
          ${data.page}(
            ${data.data}
            business: "${d.business}"
            type: "${d.type}"
            name: "${d.account_name}"
            category: "${d.category}"
            currency: "${d.currency}"
            accountNo: ${d.account_no}
          ) {
            coa {
              name
            }
          }
        }`;
      // console.log("gql:", gql);
      const callback = (data) => {
        // console.log(data.errors);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 3500,
          });
          clearForm();
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3
  // step 2
  if (action === "add") {
    // multiple props
    return <COAAdd props={{ clearForm, handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <COAEdit
        props={{
          ...props,
          clearForm,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
  // step 2
}
