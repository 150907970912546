import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// step 1
export default function Post(props) {
  const $ = window.$;
  const initialized = useRef(false);
  const navigate = useNavigate();
  const businessRef = useRef();
  const categoryRef = useRef();
  const titleRef = useRef();
  const saleTypeRef = useRef();
  const currencyRef = useRef();
  const priceRef = useRef();
  const specialPriceRef = useRef();
  const business = aim.useGetBusiness();
  const currency = aim.useGetCurrency();
  // step 2
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  const addOption = () => {
    const addOption = `
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">ជម្រើស</span>
        </div>
        <input
          class="form-control option"
          type="text"
          name="option[]"
          autoComplete="off"
          required
        />
        <div class="input-group-append">
          <button class="input-group-text delete_option bg-danger">
            លុប
          </button>
        </div>
      </div>`;
    // remove d-none class to show option
    $("#option_information").removeClass("d-none");
    $("#option_information").append(addOption);
  };
  const addExtra = () => {
    const addExtra = `
    <div class="option_row">
        <h3 class="text-primary input-group-append">
          បន្ថែមទំនិញ <button class="input-group-text delete_extra text-danger ml-2">លុប</button>
        </h3>
        <div class="input-group mb-3 row">
          <div class="input-group mb-3 col-12">
            <div class="input-group-prepend">
                <span class="input-group-text">ចំណងជើង</span>
            </div>
            <input class="form-control" type="text" autocomplete="off" onClick="this.select();" name="extra_title[]">
          </div>
            
          <div class="input-group mb-3 col">
            <div class="input-group-prepend">
              <span class="input-group-text">តម្លៃ</span>
            </div>
            <input
              class="form-control"
              type="text"
              name="extra_price[]"
              autoComplete="off"
              required
            />
          </div>

          <div class="input-group mb-3 col">
            <div class="input-group-prepend">
              <span class="input-group-text">តម្លៃពិសេស</span>
            </div>
            <input
              class="form-control"
              type="text"
              name="extra_specialPrice[]"
              autoComplete="off"
              required
            />
          </div>
        </div>    
    </div>`;
    // remove d-none class to show extra
    $("#extra_information").removeClass("d-none");
    $("#extra_information").append(addExtra);
  };
  // remove option, extra
  $(document).on("click", ".delete_option, .delete_extra", function () {
    $(this).parent().parent().remove();
  });
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (business) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        business.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      let currencyOptions = "";
      if (currency) {
        currency.forEach((element) => {
          // base 64 decode id
          const id = atob(element.node.id);
          // get id
          const id_get = id.split(":")[1];
          if (element.node.isDefault) {
            currencyOptions += `<option value="${id_get}" selected>${element.node.nameKh}</option>`;
          } else {
            currencyOptions += `<option value="${id_get}">${element.node.nameKh}</option>`;
          }
        });
      }
      const currencySelect = document.getElementById("currency");
      currencySelect.innerHTML = currencyOptions;

      if (id) {
        const gql = `query MyQuery($id: ID!) {
          saleInformation(id: $id) {
            category {
              id
              name
            }
            business {
              id
            }
            id
            name
            image
            price
            specialPrice
            status
            type {
              name
            }
            currency {
              id
              shortName
              nameKh
            }
            addoptionSet {
              id
              name
            }
            addextraSet {
              id
              title
              price
              specialPrice
            }
          }
        }`;
        const variables = {
          id,
        };
        // console.log("gql:", gql);
        const callback = (data) => {
          const d = data.data.saleInformation;
          // console.log("data:", d);
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
            navigate("/post/coa?action=add");
          } else {
            // console.log("data:", d);
            businessRef.current.value = d.business.id;
            categoryRef.current.value = d.category.name;
            titleRef.current.value = d.name;
            saleTypeRef.current.value = d.type.name;
            // select currency by nameKh
            // const currencyId = atob(d.currency.id).split(":")[1];
            // currencyRef.current.value = currencyId;
            // currencyRef text content by nameKh
            currencyRef.current[0].textContent = d.currency.nameKh;
            console.log("currency:", currencyRef.current[0].textContent);
            priceRef.current.value = d.price;
            specialPriceRef.current.value = d.specialPrice;
            // add option
            if (d.addoptionSet) {
              const addOption = (data) => {
                const addOption = `
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">ជម្រើស</span>
                    </div>
                    <input
                      class="form-control option"
                      type="text"
                      name="option[]"
                      value="${data}"
                      autoComplete="off"
                      required
                    />
                    <div class="input-group-append">
                      <button class="input-group-text delete_option bg-danger">
                        លុប
                      </button>
                    </div>
                  </div>`;
                // remove d-none class to show option
                $("#option_information").removeClass("d-none");
                $("#option_information").append(addOption);
              };
              d.addoptionSet.forEach((element) => {
                console.log("element:", element);
                addOption(element.name);
              });
            }
            // add extra
            if (d.addextraSet) {
              const addExtra = (e) => {
                const addExtra = `
                <div class="option_row">
                    <h3 class="text-primary input-group-append">
                      បន្ថែមទំនិញ <button class="input-group-text delete_extra text-danger ml-2">លុប</button>
                    </h3>
                    <div class="input-group mb-3 row">
                      <div class="input-group mb-3 col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">ចំណងជើង</span>
                        </div>
                        <input class="form-control" type="text" autocomplete="off" onClick="this.select();" name="extra_title[]" value="${e.title}">
                      </div>
                        
                      <div class="input-group mb-3 col">
                        <div class="input-group-prepend">
                          <span class="input-group-text">តម្លៃ</span>
                        </div>
                        <input
                          class="form-control"
                          type="text"
                          name="extra_price[]"
                          value="${e.price}"
                          autoComplete="off"
                          required
                        />
                      </div>
            
                      <div class="input-group mb-3 col">
                        <div class="input-group-prepend">
                          <span class="input-group-text">តម្លៃពិសេស</span>
                        </div>
                        <input
                          class="form-control"
                          type="text"
                          name="extra_specialPrice[]"
                          value="${e.specialPrice}"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>    
                </div>`;
                // remove d-none class to show extra
                $("#extra_information").removeClass("d-none");
                $("#extra_information").append(addExtra);
              };
              d.addextraSet.forEach((element) => {
                console.log("element:", element);
                addExtra(element);
              });
            }
          }
        };
        aim.jquery_free(gql, variables, callback);
      }
    }
  }, [business, currency, id, navigate, $]);
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ពត៌មានការលក់</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <select
                    id="business"
                    name="business"
                    className="form-control"
                    required
                    ref={businessRef}
                  ></select>
                </div>
                {/* ប្រភេទទំនិញ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទទំនិញ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="category"
                    id="category"
                    autoComplete="off"
                    required
                    ref={categoryRef}
                  />
                </div>
                {/* ឈ្មោះទំនិញ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះទំនិញ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="off"
                    required
                    ref={titleRef}
                  />
                </div>
                {/* sale_type */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទការលក់</span>
                  </div>
                  <select
                    className="form-control"
                    name="sale_type"
                    id="sale_type"
                    ref={saleTypeRef}
                  >
                    <option value="Food">ម្ហូប</option>
                    <option value="Drink">គ្រឿងទឹក</option>
                    <option value="Other">ផ្សេងៗ</option>
                  </select>
                </div>
                {/* រូបិយប័ណ្ណ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">រូបិយប័ណ្ណ</span>
                  </div>
                  <select
                    className="form-control"
                    name="currency"
                    id="currency"
                    autoComplete="off"
                    required
                    ref={currencyRef}
                  />
                </div>
                <div className="input-group mb-3 row">
                  {/* តម្លៃ */}
                  <div className="input-group mb-3 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">តម្លៃ</span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      name="price"
                      id="price"
                      autoComplete="off"
                      required
                      ref={priceRef}
                    />
                  </div>
                  {/* តម្លៃពិសេស */}
                  <div className="input-group mb-3 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">តម្លៃពិសេស</span>
                    </div>
                    <input
                      className="form-control"
                      type="text"
                      name="specialPrice"
                      id="specialPrice"
                      autoComplete="off"
                      required
                      ref={specialPriceRef}
                    />
                  </div>
                </div>
                {/* button options */}
                <div className="mb-3 text-center">
                  <div id="option_information" className="d-none"></div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addOption}
                  >
                    ជម្រើស
                  </button>
                </div>
                {/* button add center */}
                <div className="mb-3 text-center">
                  <div id="extra_information" className="d-none"></div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addExtra}
                  >
                    បន្ថែម
                  </button>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/sale_information")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
