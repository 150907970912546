import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./appState/AuthProvider";

const localUser = JSON.parse(localStorage.getItem("user")) || "";
const business = JSON.parse(localStorage.getItem("business")) || "";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider userData={{ user: localUser, business: business }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
/* root.render(
  <AuthProvider userData={{ user: localUser, business: business }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
