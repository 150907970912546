// step 1
// 1. update side bar
// 2. update the page in App.js
import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
// import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export default function Finan() {
  // const $ = window.$;
  const navigate = useNavigate();
  // const [coa, setCoa] = useState([]);
  const changeHandle = (e) => {
    e.preventDefault();
    const func = document.getElementById("func");
    const account_list = document.getElementById("account_list");
    const loadData = (variables = null, func = null) => {
      let gql = "";
      if (variables) {
        gql = `query MyQuery($category_In: [String] = null) {
          allCoa(category_In: $category_In) {
            edges {
              node {
                currency {
                  nameKh
                  shortName
                }
                type {
                  type
                }
                id
                category
                name
                accountNo
                finanPreparant {
                  id
                  name
                }
              }
            }
          }
        }`;
      } else {
        gql = `query MyQuery {
          allCoa {
            edges {
              node {
                currency {
                  nameKh
                  shortName
                }
                type {
                  type
                }
                id
                category
                name
                accountNo
                finanPreparant {
                  id
                  name
                }
              }
            }
          }
        }`;
      }

      /* const variables = {
        category_In: ["ទ្រព្យ", "បំណុល", "ចំណាយ"],
      }; */
      const callback = (data) => {
        // console.log("data:", data);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          // console.log("data:", data);
          account_list.innerHTML = "";
          const currencyGrops = data.data.allCoa.edges;
          // use currency short name ad main key and name as sub key
          const currencyGroup = currencyGrops.reduce((acc, obj) => {
            const key = obj.node.currency.shortName;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj.node);
            return acc;
          }, {});
          // console.log("currencyGroup:", currencyGroup);
          for (const key in currencyGroup) {
            if (Object.hasOwnProperty.call(currencyGroup, key)) {
              const element = currencyGroup[key];
              const CurrencyTitle = document.createElement("h3");
              // add attribute to element
              CurrencyTitle.className = "text-primary";
              CurrencyTitle.innerHTML = key;
              account_list.appendChild(CurrencyTitle);
              // group category
              const categoryGroup = element.reduce((acc, obj) => {
                const key = obj.category;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
              }, {});
              for (const key in categoryGroup) {
                if (Object.hasOwnProperty.call(categoryGroup, key)) {
                  const element = categoryGroup[key];
                  const CategoryTitle = document.createElement("h4");
                  // add attribute to element
                  CategoryTitle.className = "text-info ml-3";
                  CategoryTitle.innerHTML = key;
                  account_list.appendChild(CategoryTitle);
                  element.forEach((e) => {
                    const checkBox = document.createElement("input");
                    checkBox.type = "checkbox";
                    checkBox.name = "accountList[]";
                    checkBox.value = e.id;
                    checkBox.id = e.id;
                    checkBox.className = "form-check-input";
                    // console.log("func:", func);
                    // find func in finanPreparant
                    const finanPreparant = e.finanPreparant;
                    finanPreparant.forEach((element) => {
                      if (element.name === func) {
                        checkBox.checked = true;
                      }
                    });
                    // checkBox.required = true;
                    const label = document.createElement("label");
                    label.htmlFor = e.id;
                    label.className = "form-check-label";
                    label.innerHTML = `${e.name} - ${e.accountNo} - ${e.currency.nameKh}`;
                    const div = document.createElement("div");
                    div.className = "form-check ml-5";
                    div.appendChild(checkBox);
                    div.appendChild(label);
                    account_list.appendChild(div);
                  });
                }
              }
            }
          }
        }
      };
      aim.jquery_free(gql, variables, callback);
    };
    if (func.value === "ស្នើសុំ") {
      // console.log("ស្នើសុំ");
      const variables = {
        category_In: ["ទ្រព្យ"],
      };
      loadData(variables, func.value);
    } else if (func.value === "ចំណាយ") {
      // console.log("ចំណាយ");
      const variables = {
        category_In: ["ទ្រព្យ", "បំណុល", "ចំណាយ"],
      };
      loadData(variables, func.value);
    } else if (func.value === "ផ្ទេរសមតុល្យ") {
      const variables = {
        category_In: ["ទ្រព្យ", "បំណុល", "ដើមទុន", "ចំណូល", "ចំណាយ"],
      };
      loadData(variables, func.value);
    } else if (func.value === "ចំណូល") {
      // console.log("ចំណូល");
      const variables = {
        category_In: ["ទ្រព្យ", "បំណុល", "ចំណូល"],
      };
      loadData(variables, func.value);
    } else {
      console.log("ជ្រើសរើស");
    }
  };
  /* const clearForm = () => {
    // console.log("clearForm");
    // document.getElementById("addDataForm").reset();
    navigate("/finan_preparant");
  }; */
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      // console.log("submit");
      const form = document.getElementById("addDataForm");
      const formData = new FormData(form);
      const data = {};
      formData.forEach((value, key) => {
        if (data[key]) {
          if (Array.isArray(data[key])) {
            data[key].push(value);
          } else {
            data[key] = [data[key], value];
          }
        } else {
          data[key] = value;
        }
      });
      // console.log("dataJson:", data);
      const accountList = data["accountList[]"];
      // console.log("accountList:", JSON.stringify(accountList));
      // console.log("accountList:", accountList);
      const gql = `mutation {
        createFinanPreparant(
          name: "${data.func}"
          account: ${JSON.stringify(accountList)}
        ) {
          preparant {
            name
          }
        }
      }`;
      const callback = (data) => {
        // console.log(data.errors);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 3500,
          });
          navigate(0);
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log("error:", error);
    }
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">កំណត់ការអនុញ្ញាត</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              onSubmit={handleSubmit}
              className="addDataForm form col-12 p-0 center-block mt-4"
            >
              <div className="card-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">មុខងារ</span>
                  </div>
                  <select
                    id="func"
                    name="func"
                    className="form-control"
                    required
                    onChange={changeHandle}
                  >
                    <option value="">ជ្រើសរើស</option>
                    <option value="ស្នើសុំ">ស្នើសុំ</option>
                    <option value="ចំណាយ">ចំណាយ</option>
                    <option value="ផ្ទេរសមតុល្យ">ផ្ទេរសមតុល្យ</option>
                    <option value="ចំណូល">ចំណូល</option>
                  </select>
                </div>
                <div id="account_list"></div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
