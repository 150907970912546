import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export default function Post(props) {
  console.log("users edit");
  const $ = window.$;
  const initialized = useRef(false);
  const business = aim.useGetBusiness();
  const navigate = useNavigate();
  // console.log(props);
  const id = props.props.searchParams.get("id");
  // console.log(id);
  // Create useRef for each input whit one line
  const lastname = useRef(null),
    firstname = useRef(null),
    // password = useRef(null),
    // confirm_password = useRef(null),
    birthdaySelect = useRef(null),
    phone = useRef(null),
    email = useRef(null),
    genderSelect = useRef(null),
    position = useRef(null),
    address = useRef(null),
    // active whit select option
    activeSelect = useRef(null),
    businessSelect = useRef(null);

  useEffect(() => {
    console.log("useEffect users edit");
    if (!initialized.current) {
      initialized.current = true;
      // console.log("useEffect");
      props.props.validate();
      props.props.birthday();
    }
    if (business) {
      const businessSelect = document.getElementById("business");
      businessSelect.innerHTML = "";
      // add option to select
      business.forEach((element) => {
        // const option = document.createElement("option");
        // option.value = element.node.id;
        // option.text = element.node.name;
        element.node.businessSet.edges.forEach((v) => {
          const option = document.createElement("option");
          option.value = v.node.id;
          option.text = v.node.business + " - " + element.node.name;
          businessSelect.appendChild(option);
        });
      });
    }
    const gql = `query MyQuery($id: ID!) {
      accounts(id: $id) {
        user {
          isActive
          email
          firstName
          lastName
          username
        }
        address
        birthday
        gender
        phone {
          id
          number
        }
        position
        business {
          edges {
            node {
              id
              business
            }
          }
        }
      }
    }`;
    const variables = {
      id,
    };
    const callback = (data) => {
      const d = data.data.accounts;
      // console.log(d);
      if (data.errors) {
        // console.log(data.errors[0].message);
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
          confirmButtonText: "យល់ព្រម",
        });
        navigate("/post/user?action=add");
        // return false;
      } else {
        lastname.current.value = d.user.lastName;
        firstname.current.value = d.user.firstName;
        // phone.current.value = d.phone;
        let phoneValue = "";
        d.phone.forEach((element) => {
          // add comma if phone is not empty
          if (phoneValue !== "") {
            phoneValue += ", ";
          }
          phoneValue += element.number;
        });
        phone.current.value = phoneValue;
        email.current.value = d.user.email;
        position.current.value = d.position;
        address.current.value = d.address;
        if (d.user.isActive) {
          activeSelect.current.value = "1";
        } else {
          activeSelect.current.value = "0";
        }
        if (d.gender === "M") {
          genderSelect.current.value = "M";
        } else if (d.gender === "F") {
          genderSelect.current.value = "F";
        } else {
          genderSelect.current.value = "O";
        }
        // bootstrapBirthday set value
        const birthday = d.birthday.split("-");
        const year = birthday[0];
        const month = Number(birthday[1]);
        const day = Number(birthday[2]);
        birthdaySelect.current.value = d.birthday;
        const bDay = 'select[name="birthday[day]"]',
          bMonth = 'select[name="birthday[month]"]',
          bYear = 'select[name="birthday[year]"]';
        // use react js to set value with querySelector
        document.querySelector(bDay).value = day;
        document.querySelector(bMonth).value = month;
        document.querySelector(bYear).value = year;
        // console.log("data:", d.business);
        const business = document.getElementById("business");
        d.business.edges.forEach((element) => {
          const option = business.querySelector(
            `option[value="${element.node.id}"]`
          );
          // console.log("option:", option);
          if (option) {
            option.selected = true;
          }
        });
        $(".business").select2();
      }
    };
    aim.jquery_free(gql, variables, callback);
  }, [id, navigate, $, business, props.props]);
  const handleSubmit = (e) => {
    e.preventDefault();
    // data = id
    props.props.handleSubmit({ page: "update", data: id });
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានអ្នកប្រើប្រាស់</h2>
            </div>
            <form
              method="post"
              className="form col-12 p-0 center-block mt-4"
              id="addDataForm"
              name="addDataForm"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">លេខទូរស័ព្ទ</span>
                    </div>
                    <input
                      className="form-control"
                      name="phone"
                      id="phone"
                      type="text"
                      autoComplete="off"
                      required
                      ref={phone}
                    />
                  </div>
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អ៊ីម៉ែល</span>
                    </div>
                    <input
                      className="form-control"
                      name="email"
                      id="email"
                      type="email"
                      autoComplete="off"
                      ref={email}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">នាមត្រកូល</span>
                    </div>
                    <input
                      className="form-control"
                      name="lastname"
                      id="lastname"
                      type="text"
                      autoComplete="off"
                      ref={lastname}
                    />
                  </div>
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">នាមខ្លួន</span>
                    </div>
                    <input
                      className="form-control"
                      name="firstname"
                      id="firstname"
                      type="text"
                      autoComplete="off"
                      ref={firstname}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ពាក្យសម្ងាត់</span>
                    </div>
                    <input
                      className="form-control"
                      name="password"
                      id="password"
                      type="password"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">បញ្ជាក់</span>
                    </div>
                    <input
                      className="form-control"
                      name="confirm_password"
                      id="confirm_password"
                      type="password"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
                <div className="row col-12 input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ថ្ងៃកំណើត</span>
                  </div>
                  <input
                    type="text"
                    name="birthday"
                    id="birthday"
                    className="form-control"
                    ref={birthdaySelect}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ភេទ</span>
                  </div>
                  <select
                    className="form-control"
                    name="gender"
                    id="gender"
                    required
                    ref={genderSelect}
                  >
                    <option value="M">ប្រុស</option>
                    <option value="F">ស្រី</option>
                    <option value="O">មិនស្គាល់</option>
                  </select>
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">តួនាទី</span>
                  </div>
                  <input
                    className="form-control"
                    name="position"
                    id="position"
                    type="text"
                    autoComplete="off"
                    ref={position}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាស័យដ្ឋាន</span>
                  </div>
                  <input
                    className="form-control"
                    name="address"
                    id="address"
                    type="text"
                    autoComplete="off"
                    ref={address}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ស្ថានភាពអ្នកប្រើ</span>
                  </div>
                  <select
                    className="form-control"
                    name="active"
                    id="active"
                    required
                    ref={activeSelect}
                  >
                    <option value="1">ប្រើប្រាស់</option>
                    <option value="0">មិនប្រើប្រាស់</option>
                  </select>
                </div>
                <div className="input-group mb-4 card-default">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ជ្រើសរើសអាជីវកម្ម</span>
                  </div>
                  <select
                    className="form-control business select2"
                    multiple="multiple"
                    name="business[]"
                    id="business"
                    ref={businessSelect}
                  ></select>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/users")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
