// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import StockOutAdd from "./StockOutAdd";
import StockOutEdit from "./StockOutEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
// import { CallbackCheck } from "./HandleFunction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// step 2
export default function Post(props) {
  console.log("stock post");
  // step 2
  const navigate = useNavigate();
  const action = props.searchParams.get("action");

  const handleSubmit = (data) => {
    try {
      const $ = window.$;
      // edit data
      let stock = $("#addDataForm").serializeArray();
      // convert to json string
      stock = JSON.stringify(stock);
      // console.log("stock", stock);
      const dataStock = { inventory: stock };

      const page = "postProductStockOut";
      if (data.page !== "update") {
        data.data = `id: null`;
      } else {
        data.data = `id: "${data.data}"`;
      }
      const gql = `
        mutation MyMutation($inventory: GenericScalar = {}) {
          ${page}(stockData: {inventory: $inventory}, ${data.data}) {
            success
          }
        }
      `;
      const variables = dataStock;

      const callback = (data) => {
        // console.log(data.errors);
        /* data.page = page;
        // edit data
        let action = "ស្តុក";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }

        let dataMessage = `
<b>${action}</b>

<b>ស្តុក:</b> ស្តុក`;

        dataMessage = dataMessage.replace(/\n/g, "\\n");
        // edit data Telegram Function
        const functionName = "Stock";
        const gql = `
          mutation MyMutation {
            sendTelegramMessage(
              text: "${dataMessage}"
              functionName: "${functionName}"
            ) {
              success
            }
          }
        `;
        // edit data
        CallbackCheck(data, gql);
        navigate(`/${functionName}`); */
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 3500,
          });
        }
      };
      // console.log(gql, variables);
      aim.jquery_free(gql, variables, callback);
    } catch (error) {
      console.error("error:", error);
      navigate("/stock");
    }
  };
  // step 3

  if (action === "add") {
    return <StockOutAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <StockOutEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
