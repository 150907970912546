// step 1
// 1. update side bar
// 2. update the page in App.js
import { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// step 2
export default function Table() {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("Table");
      const gql = `query MyQuery {
        allTable {
          edges {
            node {
              id
              name
              number
              tableType
            }
          }
        }
      }`;
      const callback = (data) => {
        // console.log(data.errors);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          // console.log(data);
          if (data.data.allTable.edges && data.data.allTable.edges.length > 0) {
            let table = "";
            let tableSource = "";
            let tableData = "";
            data.data.allTable.edges.forEach((x) => {
              tableSource = "bg-success";
              tableData = `<div class="info-box-content">
                              <a href="sale_order?table=${x.node.id}&table_type=${x.node.tableType}" style="color: white">
                                <h4 class="info-box-text">${x.node.name}</h4>
                                <span class="info-box-number">amount</span>
                              </a>
                            </div>`;
              table += `<div class="col-6 col-sm-3 my-2" style="cursor: pointer;">
                    <div class="info-box ${tableSource} style="min-height: 100%">
                        ${tableData}
                    </div>
                </div>`;
            });
            document.querySelector(".all_table").innerHTML = table;
          } else {
            // document get element by id
            document.getElementById(
              "form_action_table_sale"
            ).innerHTML = `<div class="row justify-content-center">
              <div class="text-center col-4 col-sm-3">
                  <a href="post/table?action=add" id="NewTable" class="more_info">
                      <i class="fa fa-plus" style="font-size:128px;"></i><br>
                      <span>បង្កើតតុ</span>
                  </a>
              </div>						
          </div>`;
          }
        }
      };
      aim.jquery_free(gql, {}, callback);
    }
  }, []);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="form_action" id="form_action_table_sale">
            <div id="tableInfo">
              <div id="info" className="row justify-content-between pt-3">
                <div className="col-5 pl-5">
                  <span className="text-success">
                    F - <span>ទំនេរ</span>
                  </span>
                  <span className="text-danger">
                    G - <span>មានភ្ញៀវ</span>
                  </span>
                  <span className="text-warning">
                    B - <span>បានកក់ទុក</span>
                  </span>
                </div>
                <div className="col-4">
                  <div className="card collapsed-card">
                    <div
                      className="card-header bg-primary"
                      data-card-widget="collapse"
                    >
                      <h3 className="card-title">ជ្រើសរើសសកម្មភាព</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-plus" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0" style={{ display: "none" }}>
                      <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-warning" /> កក់ទុក
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-primary" /> ប្ដូរតុ
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-primary" /> រួមតុ
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-info" /> លុបការកក់
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row justify-content-md-center"
                id="row_filter_table"
              >
                <div className="input-group mt-3 col-6">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខតុ</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="filter_number"
                    name="filter_number"
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              </div>
              <div className="row all_table" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
