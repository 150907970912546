import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Currency() {
  const $ = window.$;
  const initialized = useRef(false);
  const page_name = "stock";
  const tbId = `tb_${page_name}`;
  const formOption = [
    <Link
      to={`/post/${page_name}?action=addMore`}
      className="btn btn-success pull-right mx-1 addMore"
      key="addMore"
    >
      បន្ថែមចំនួន
    </Link>,
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
      key="addRow"
    >
      <i className="fa fa-plus" />
    </Link>,
  ];
  const thead = aim.datatableHeadFoot(
    [
      "លរ",
      "រូបភាព",
      "ព័ត៌មាន",
      "បរិមាណចុងក្រោយ",
      "អ្នកផ្គត់ផ្គង់",
      "ស្ថានភាព",
      "សកម្មភាព",
    ],
    null
  );
  const sumAmount = null;

  const navigate = useNavigate();
  const statusHandle = (id, data, status) => {
    try {
      const gql = `mutation {
        updateCurrency(
          id: "${id}"
          ${status}: ${data}
        ) {
          currency {
            shortName
            nameKh
            status
            isDefault
          }
        }
      }`;
      const callback = (data) => {
        // console.log(data);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 2000,
          });
          // reload page when timer end
          if (status === "isDefault") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const data_edit = JSON.parse(e.target.dataset.edit.replace(/'/g, '"'));
      const id = data_edit.id;
      // console.log("id:", id, "page:", page);

      const formData = new FormData();
      formData.append("image_file", e.target.files[0]);
      try {
        const gql = `mutation MyMutation($id: String, $image: Upload) {
          uploadImageStock(id: $id, image: $image) {
            success
          }
        }`;
        const variables = {
          id: id,
          image: null,
        };
        const graphqlRequestBody = {
          operationName: null,
          query: gql,
          variables: variables,
        };
        const graphqlRequestBodyString = JSON.stringify(graphqlRequestBody);
        const graphqlFormData = new FormData();
        graphqlFormData.append("operations", graphqlRequestBodyString);
        graphqlFormData.append("map", '{"0": ["variables.image"]}');
        graphqlFormData.append(0, e.target.files[0]);
        // upload file

        const callback = (data) => {
          // console.log(data);
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            withReactContent(Swal).fire({
              icon: "success",
              title: "ជោគជ័យ",
              showConfirmButton: false,
              timer: 2000,
            });
            // reload page when timer end
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        };
        aim.jquery_uploadFile(graphqlFormData, callback);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No image selected");
    }
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect Stock.js");
      aim.loadData(
        {
          // columns: columnLoad,
          tbId: tbId,
          page_name: page_name,
          load_page: "allProductsStock",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                name
                image
                code
                location
                unitBox
                uom
                quantity
                price
                specialPrice
                status
                currency {
                  id
                  nameKh
                  shortName
                }
                category {
                  id
                  name
                }
                supplier {
                  id
                  name
                  phone
                  address
                }
                productCode {
                  code
                }
                latestproductquantitySet {
                  id
                  quantity
                }
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4, 5, 6],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "name_kh", "", "", ""],
          searching: ["name", "category"],
          // function
          data_execute: (result, load_page) => {
            function action_group(action) {
              var data = `<div class="btn-group">
                <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    សកម្មភាព
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    ${action}
                </div>
              </div>`;
              return data;
            }
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              // base 64 decode id
              // const id = atob(v.node.id);
              // get id
              // const id_get = id.split(":")[1];
              let status = "";
              let status_val = 0;
              if (v.node.status === true) {
                status = ` checked`;
                status_val = 1;
              } else {
                status = ``;
                status_val = 0;
              }
              let checkbox_status = `<label class="switch">
                <input class="status_table updateStatus" type="checkbox" ${status} data-id="${v.node.id}" value="${status_val}">
                <span class="slider round"></span>
              </label>`;
              let img = "";
              if (v.node.image === "") {
                // PUBLIC folder in react public
                img = `<img src="/dist/img/noimage.jpg" class="fit mr-2 img-thumbnail img-upload" style="cursor: pointer" width="100px" data-edit="{'id':'${v.node.id}','page':'${page_name}'}">`;
              } else {
                img = `<img src="https://landscape.aimsarik.com/media/${v.node.image}" class="fit mr-2 img-thumbnail" style="cursor: pointer" width="100px" data-edit="{'id':'${v.node.id}','page':'${page_name}'}">`;
              }
              let action = `<div class="dropdown-item-action">
                  <span class="w-100 btn btn-success">
                    <i class="fa fa-pencil-square-o editData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"> កែសម្រួល</i>
                  </span>
                </div> 
                <div class="dropdown-item-action">
                  <span class="w-100 btn btn-success">
                    <i class="fa fa-plus add_notification"> ការជូនដំណឹង</i>
                  </span>
                </div>
                <div class="dropdown-item-action">
                  <span class="w-100 btn btn-danger">
                    <i class="fa fa-trash deleteRow" aria-hidden="true" data-delete="{'id':'${v.node.id}','page':'${page_name}'}"> លុប</i>
                  </span>
                </div>`;
              action = action_group(action);
              var tb_row = [
                `${number++}`,
                `${img}`,
                `${v.node.name}<br>
                លេខកូដ: ${v.node.code}<br>
                តម្លៃលក់ = ${aim.currencyFormat(
                  v.node.price,
                  v.node.currency.shortName,
                  2
                )}<br>
                តម្លៃលក់ ពិសេស = ${aim.currencyFormat(
                  v.node.specialPrice,
                  v.node.currency.shortName,
                  2
                )}<br>
                ទីតាំង: ${v.node.location}`,
                `${aim.addCommas(
                  v.node.latestproductquantitySet[0].quantity
                )} ${v.node.uom}`,
                `លេខកូដទិញ: ${v.node.productCode.code}<br>
                ឈ្មោះ អ្នកផ្គត់ផ្គង់: ${v.node.supplier.name}<br>
                ទូរស័ព្ទ អ្នកផ្គត់ផ្គង់: ${v.node.supplier.phone}<br>`,
                `${checkbox_status}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      // document.queryselector .editData
      document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
        const el = e.target;
        if (el.classList.contains("editData")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          const { id, page } = data_edit;
          navigate(`/post/${page}?action=edit&id=${id}`);
        } else if (el.classList.contains("updateStatus")) {
          const id = el.dataset.id;
          const status = el.checked;
          statusHandle(id, status, "status");
        } else if (el.classList.contains("updateDefault")) {
          const id = el.dataset.id;
          const is_status = el.checked;
          statusHandle(id, is_status, "isDefault");
        } else if (el.classList.contains("img-upload")) {
          $("#image_file").attr("data-edit", el.dataset.edit);
          $("#image_file").click();
        }
      });
      aim.dateRange(tbId);
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
          <form
            method="post"
            encType="multipart/form-data"
            name="form_upload_image"
            id="form_upload_image"
            className="col-12 d-none"
          >
            <div className="modal-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Image Upload</span>
                </div>
                <input
                  type="file"
                  name="image_file"
                  id="image_file"
                  required
                  className="form-control input-lg"
                  accept="image/*"
                  onChange={(e) => {
                    HandleSubmit(e);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
