// step 3
import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
// step 1
export default function Post(props) {
  console.log("Tg edit");
  const $ = window.$;
  // step 2
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  const botNameRef = useRef();
  const chatIdRef = useRef();
  const groupNameRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("useEffect");
    const gql = `query MyQuery {
      allTelegram {
        edges {
          node {
            id
            botName
          }
        }
      }
      allFunctionTelegram {
        edges {
          node {
            id
            name
          }
        }
      }
      telegramChatGroup(id: "${id}") {
        id
        chatId
        groupName
        Telegram {
          id
          botName
        }
        FunctionTg {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }`;
    const variables = {
      id,
    };
    const callback = (data) => {
      // console.log(data);
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
          confirmButtonText: "យល់ព្រម",
        });
      } else {
        const botName = document.getElementById("botName");
        botName.innerHTML = "";
        data.data.allTelegram.edges.forEach((element) => {
          const option = document.createElement("option");
          option.value = element.node.id;
          option.text = element.node.botName;
          botName.appendChild(option);
        });
        const function_name = document.getElementById("function_name");
        function_name.innerHTML = "";
        // async forEach then console.log the Loop is completed
        const forEachAsync = async (array, callback) => {
          for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
          }
          // console.log("Loop is completed");
          setTimeout(() => {
            const d = data.data.telegramChatGroup;
            botNameRef.current.value = d.Telegram.id;
            chatIdRef.current.value = d.chatId;
            groupNameRef.current.value = d.groupName;
            d.FunctionTg.edges.forEach((element) => {
              const function_name = document.getElementById("function_name");
              // select the option
              const option = function_name.querySelector(
                `option[value="${element.node.id}"]`
              );
              option.selected = true;
            });
            $(".duallistbox").bootstrapDualListbox();
          }, 200);
        };

        forEachAsync(data.data.allFunctionTelegram.edges, async (element) => {
          const option = document.createElement("option");
          option.value = element.node.id;
          option.text = element.node.name;
          function_name.appendChild(option);
        });
      }
    };
    aim.jquery_free(gql, variables, callback);
  }, [$, id, navigate]);
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានតេលេក្រាម</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* ឈ្មោះBot */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Bot</span>
                  </div>
                  <select
                    className="form-control"
                    type="text"
                    name="botName"
                    id="botName"
                    autoComplete="off"
                    required
                    ref={botNameRef}
                  ></select>
                </div>
                {/* chat ID */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Chat ID</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="chatId"
                    id="chatId"
                    autoComplete="off"
                    required
                    ref={chatIdRef}
                  />
                </div>
                {/* ឈ្មោះ​ក្រុម */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះ​ក្រុម</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="groupName"
                    id="groupName"
                    autoComplete="off"
                    required
                    ref={groupNameRef}
                  />
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>ជ្រើសរើសមុខងារ</label>
                    <select
                      className="form-control duallistbox"
                      multiple="multiple"
                      name="function_name[]"
                      id="function_name"
                    ></select>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/telegram")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
