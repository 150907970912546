import { useEffect, useRef } from "react";
import "./order.css";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Table() {
  const initialized = useRef(false);
  const $ = window.$;
  // tap_panes_content("typeFood", "foodCategoryFlex", "foodItem")
  function tap_panes_content(name) {
    const flexslider_name = `flexslider carousel flexslider_${name}`;
    const name_category_slide = `${name}_category_slide`;
    const itemName = `${name}Item`;
    const typeName = `type${name}`;
    const categoryName = `${name}CategoryFlex`;
    const data = (
      <div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">ប្រភេទ</span>
          </div>
          <select
            name="type"
            id={typeName}
            className="form-control custom-select"
          >
            <option value="All">ទាំងអស់</option>
          </select>
        </div>
        <div id={categoryName}>
          <div className={flexslider_name}>
            <ul className="slides" id={name_category_slide}>
              <li data-item="All" style={{ cursor: "pointer" }}>
                <div
                  className="d-flex align-items-center justify-content-center bg-primary border border-dark rounded"
                  style={{ width: 100, height: 100 }}
                >
                  <span>ទាំងអស់</span>
                </div>
                <br />
                <div className="text-center" style={{ marginTop: "-12px" }}>
                  ទាំងអស់
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center my-4" id={itemName}></div>
      </div>
    );
    return data;
  }
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect");
      let foodStatus = 0;
      let stockStatus = 0;
      let otherStatus = 0;
      let setStatus = 0;
      let allItems = [];
      const loadData = (tap_name) => {
        if (tap_name === "food") {
          foodStatus = 1;
        } else if (tap_name === "stock") {
          stockStatus = 1;
        } else if (tap_name === "other") {
          otherStatus = 1;
        } else if (tap_name === "set") {
          setStatus = 1;
        }
        const gql = `query MyQuery {
          allSaleInformation(type_Name_Icontains: "${tap_name}") {
            edges {
              node {
                type {
                  id
                  name
                }
                category {
                  id
                  name
                }
                id
                name
                image
                price
                specialPrice
                status
                currency {
                  id
                  nameKh
                  shortName
                }
                addoptionSet {
                  id
                  name
                }
                addextraSet {
                  id
                  title
                  price
                  specialPrice
                }
              }
            }
          }
        }`;
        const callback = (data) => {
          // console.log(data);
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            const d = data.data.allSaleInformation.edges;
            allItems.push(...d);
            // console.log(d);
            // loop through the data
            let item = "";
            d.forEach((e) => {
              // type of food select option
              const typeOfItem = `<option>${e.node.category.name}</option>`;
              $(`#type${tap_name}`).append(typeOfItem);
              // type of food flex slider
              const itemCategoryFlex = `<li data-item="${e.node.category.name}" class="${e.node.category.name}" style="cursor: pointer">
                <div class="d-flex align-items-center justify-content-center border border-dark rounded" style="width: 100px; height: 100px">
                <img class="fit" alt="" src="/dist/img/noimage.jpg" draggable="false">
                </div><br>
                <div class="text-center" style="margin-top:-12px">${e.node.name}</div>
              </li>`;
              $(`#${tap_name}_category_slide`).append(itemCategoryFlex);
              // food item image
              let img = "/dist/img/noimage.jpg";
              if (e.node.image) {
                img = `https://landscape.aimsarik.com/media/${e.node.image}`;
              }
              item += `<div class="col-md-3 col-sm-2 ${e.node.category.name} chooseType mb-5 text-center" style="cursor: pointer; height: 100px;">
                <img 
                  class="products fit" 
                  data-category_id="${e.node.category.id}"
                  data-id="${e.node.id}" 
                  alt="" 
                  src="${img}" 
                  width="100" height="100"><br>
                <p class="text-center mt-2">${e.node.name} - ${e.node.category.name}</p>
              </div>`;
            });
            $(`#${tap_name}Item`).html(item);
            // flex
            $(`.flexslider_${tap_name}`).flexslider({
              animation: "slide",
              animationLoop: false,
              itemWidth: 100,
              itemMargin: 5,
              mousewheel: true,
              minItems: 5,
              slideshow: false,
              controlNav: true,
              directionNav: false,
              move: 2,
              start: function (slider) {
                $("body").removeClass("loading");
              },
            });
            $(".flexslider").swipe({
              swipeLeft: function (
                event,
                direction,
                distance,
                duration,
                fingerCount
              ) {
                console.log("swipeLeft");
                this.flexslider("next");
              },
              swipeRight: function () {
                console.log("swipeRight");
                this.flexslider("prev");
              },
              /*threshold: 0,
              tap: function (event, target) {
              window.location = $(this).find(".carousel-item.active a").attr("href");
              },
              excludedElements: "label, button, input, select, textarea, .noSwipe" */
            });
            // flex
          }
        };
        aim.jquery_free(gql, {}, callback);
      };

      $(".panel_tab a").click(function () {
        const tap_name = $(this).attr("data-title");
        if (tap_name === "food" && foodStatus === 0) {
          loadData("food");
        } else if (tap_name === "stock" && stockStatus === 0) {
          loadData("stock");
        } else if (tap_name === "other" && otherStatus === 0) {
          loadData("other");
        } else if (tap_name === "set" && setStatus === 0) {
          loadData("set");
        }
      });
      // first load data from food
      loadData("food");
      $("#form_action_order").on("click", ".flexslider li", function () {
        $(this).attr("data-item");
        var typeSelect = $(this).attr("data-item");
        if (typeSelect === "All") {
          $(".chooseType").show();
        } else {
          $(".chooseType").hide();
          $("." + typeSelect).show();
        }
      });

      $(document).on("change keyup", "#order-form", function () {
        let price = $("#price").val();
        let quantity = $("#quantity").val();
        // sum add extra price
        let addExtraPrice = 0;
        $(".addExtra:checked").each(function () {
          addExtraPrice += parseFloat($(this).data("price"));
        });
        // console.log(price, quantity, addExtraPrice);
        let total = (parseFloat(price) + addExtraPrice) * parseFloat(quantity);
        $("#total").val(total);
      });
      // product click
      $("#form_action_order").on("click", ".products", function () {
        const id = $(this).attr("data-id");
        // find product by id
        const product = allItems.find((v) => v.node.id === id);
        // console.log(product);
        // check add option
        let addoptionSet = "";
        if (product.node.addoptionSet.length > 0) {
          product.node.addoptionSet.forEach((v) => {
            addoptionSet += `<div class="icheck-primary d-inline">
                <input class="form-check-input" type="checkbox" value="${v.id}" id="option_box_${v.id}" name="option[]">
                <label class="form-check-label" for="option_box_${v.id}">
                    ${v.name}
                </label>
            </div>`;
          });
          const addTitle = `<h4 class="text-primary">ជម្រើស</h4>
          ${addoptionSet}`;
          addoptionSet = addTitle;
        }
        // check add extra
        let addextraSet = "";
        if (product.node.addextraSet.length > 0) {
          product.node.addextraSet.forEach((v) => {
            addextraSet += `<div class="icheck-primary d-inline">
                <input class="form-check-input addExtra" type="checkbox" data-price="${v.price}" value="${v.id}--${v.price}" id="extra_box_${v.id}" name="extra[]">
                <label class="form-check-label" for="extra_box_${v.id}">
                    ${v.title} <span class="text-danger">${v.price}</span>
                </label>
            </div>`;
          });
          const addTitle = `<h4 class="text-primary">បន្ថែម</h4>
          ${addextraSet}`;
          addextraSet = addTitle;
        }
        withReactContent(Swal)
          .fire({
            html: `<div class=col">
                <h4 class="mt-1">ដំណើរការលក់</h4>
                <form id="order-form" method="post">
                  <hr>
                  <h3 class="text-primary text-left">${product.node.name}</h3>
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">តម្លៃ</span>
                      </div>	
                      <input type="text" name="price" class="form-control number" id="price" value="${product.node.price}">
                      <div class="input-group-append">
                          <input type="hidden" name="productId" id="productId" value="${product.node.id}">
                          <span class="input-group-text text-danger">${product.node.currency.shortName}</span>
                      </div>	
                  </div>

                  <div class="form-group clearfix text-left">
                      ${addoptionSet}
                  </div>
                  
                  <div class="form-group clearfix text-left">
                      ${addextraSet}
                  </div>

                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បរិមាណ</span>
                      </div>	
                      <input type="number" name="quantity" id="quantity" value="1" class="form-control number" required autocomplete="off">
                  </div>
                  
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">សរុប</span>
                      </div>	
                      <input type="text" name="total" id="total" class="form-control number" readonly value="${product.node.price}">
                      <div class="input-group-append">
                          <span class="input-group-text text-danger">${product.node.currency.shortName}</span>
                      </div>	
                  </div>

                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បរិយាយ</span>
                      </div>
                      <textarea class="form-control" name="description" id="description" rows="2"></textarea>
                  </div>

                </form>
              </div>`,
            showCancelButton: true,
            confirmButtonText: "យល់ព្រម",
            cancelButtonText: "បោះបង់",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              // console.log("SubmitHandle");
              const dataForm = {};
              $("#order-form")
                .serializeArray()
                .map(function (x) {
                  if (x.name.indexOf("[]") !== -1) {
                    if (Array.isArray(dataForm[x.name])) {
                      dataForm[x.name].push(x.value);
                    } else {
                      dataForm[x.name] = [x.value];
                    }
                  } else {
                    dataForm[x.name] = x.value;
                  }
                  return true;
                });
              const d = dataForm;
              // console.log(d);
              const callback = (data) => {
                // console.log(data.errors);
                if (data.errors) {
                  withReactContent(Swal).fire({
                    icon: "error",
                    title: "មានបញ្ហា",
                    html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                    confirmButtonText: "យល់ព្រម",
                  });
                } else {
                  withReactContent(Swal).fire({
                    icon: "success",
                    title: "ជោគជ័យ",
                    showConfirmButton: false,
                    timer: 3500,
                  });
                }
              };

              let addOption = `addOption: null`;
              if (d["option[]"]) {
                addOption = `addOption: ${JSON.stringify(d["option[]"])}`;
              }
              let addExtra = `addExtra: null`;
              let addExtraPrice = `addExtraPrice: null`;
              if (d["extra[]"]) {
                addExtra = [];
                addExtraPrice = [];
                d["extra[]"].forEach((v) => {
                  const extra = v.split("--");
                  const extraId = extra[0];
                  const extraPrice = extra[1];
                  addExtra.push(extraId);
                  addExtraPrice.push(extraPrice);
                });
                addExtra = `addExtra: ${JSON.stringify(addExtra)}`;
                addExtraPrice = `addExtraPrice: ${JSON.stringify(
                  addExtraPrice
                )}`;
              }
              const page = "postSaleOrder";
              const gql = `
                mutation MyMutation {
                  ${page}(
                    productId: "${d.productId}"
                    ${addOption}
                    description: "${d.description}"
                    price: ${d.price}
                    ${addExtra}
                    ${addExtraPrice}
                    quantity: ${d.quantity}
                    total: ${d.total}
                  ) {
                    success
                  }
                }
              `;
              // console.log(gql);
              aim.jquery_free(gql, {}, callback);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: `${result.value.title}!`,
                html: `Submitted successfully: ${result.value.title}`,
                confirmButtonText: "យល់ព្រម",
              });
            }

            // console.log(result);
          });
      });
    }
  }, [$]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="form_action" id="form_action_order">
            {/* form_action_order */}
            <div className="row">
              <div id="tableInfo" className="col-12">
                <div id="noti">
                  <div className="box1 text-center mt-2"></div>
                  <div
                    className="data_order_list"
                    style={{
                      backgroundColor: "#c9e0fb",
                      position: "absolute",
                      zIndex: 999,
                      transform: "translate(-50%, 0)",
                      left: "50%",
                      padding: 12,
                      display: "none",
                    }}
                  ></div>
                </div>
                <ul
                  className="nav nav-tabs nav-pills nav-justified"
                  role="tablist"
                >
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="pill"
                      href="#food"
                      data-title="food"
                    >
                      ម្ហូប
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link setup_table"
                      data-toggle="pill"
                      href="#stock"
                      data-title="stock"
                    >
                      ផលិតផលក្នុងស្តុក
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#other"
                      data-title="other"
                    >
                      ផ្សេងៗ
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#set"
                      data-title="set"
                    >
                      ឈុត
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="food" className="tab-pane mt-4 active">
                    {tap_panes_content("food")}
                  </div>
                  <div id="stock" className="tab-pane mt-4">
                    {tap_panes_content("stock")}
                  </div>
                  <div id="other" className="tab-pane mt-4">
                    {tap_panes_content("other")}
                  </div>
                  <div id="set" className="tab-pane mt-4">
                    {tap_panes_content("set")}
                  </div>
                </div>
              </div>
            </div>
            {/* form_action_order */}
          </div>
        </div>
      </div>
    </div>
  );
}
