import * as aim from "../../js/function.easy7d";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// step 1
export default function Post(props) {
  console.log("business add");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  const business = aim.useGetBusiness();
  useEffect(() => {
    console.log("useEffect business");
    if (business) {
      const companySelect = document.getElementById("company");
      companySelect.innerHTML = "";
      // add option to select
      business.forEach((element) => {
        const option = document.createElement("option");
        option.value = element.node.id;
        option.text = element.node.name;
        companySelect.appendChild(option);
      });
    }
  }, [business]);
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានអាជីវកម្ម</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="business"
                    id="business"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* company */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ក្រុមហ៊ុន</span>
                  </div>
                  <select
                    className="form-control"
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="off"
                    required
                  ></select>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/business")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
