import React, { createContext, useState } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children, userData }) => {
  // userData={{ user: localUser, business: business }}
  const [user, setUser] = useState(userData);
  // console.log("AuthProvider", user);
  const setAuthUser = (userInfo) => setUser(userInfo);
  return (
    <AuthContext.Provider value={{ user, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
