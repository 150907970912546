// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import TelegramAdd from "./TelegramAdd";
import TelegramEdit from "./TelegramEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

// step 2
export default function Post(props) {
  // step 2
  console.log("Tg Post");
  const navigate = useNavigate();
  // console.log(props);
  const action = props.searchParams.get("action");

  // step 3
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // edit data
      const page = "postTelegramChatGroup";

      const callback = (data) => {
        // console.log(data.errors);
        data.page = page;
        // edit data
        let action = "តេលេក្រាម";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }
        let dataMessage = `
<b>${action}</b>
<b>ឈ្មោះក្រុម:</b> ${d.groupName}
<b>Chat ID:</b> ${d.chatId}
<b>Telegram:</b> ${d.botName}`;
        /* <b>មុខងារ:</b> ${d["function_name[]"]} */
        // convert to string with break line
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        const gql = `
              mutation MyMutation {
                sendTelegramMessage(
                  text: "${dataMessage}"
                  functionName: "Telegram"
                ) {
                  success
                }
              }
            `;
        CallbackCheck(data, gql);
        navigate("/telegram");
      };

      const function_name = d["function_name[]"];
      const gql = `
        mutation MyMutation {
          ${page}(
            ${data.data}
            chatId: "${d.chatId}"
            groupName: "${d.groupName}"
            telegram: "${d.botName}"
            functionName: ${JSON.stringify(function_name)}
          ) {
            success
          }
        }
      `;
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3

  if (action === "add") {
    return <TelegramAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <TelegramEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
