import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
// step 1
export default function Post(props) {
  const $ = window.$;
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "addMore", data: null });
  };
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      $("#searchProduct").keyup(function () {
        // console.log("searchProduct", $(this).val());
        const item = $(this).val();
        const gql = `query MyQuery {
          autoCategories {
            edges {
              node {
                name
                productSet(name_Icontains: "${item}") {
                  edges {
                    node {
                      id
                      name
                      code
                      location
                      unitBox
                      uom
                      price
                      specialPrice
                      currency {
                        nameKh
                        shortName
                      }
                      quantity
                      latestproductquantitySet {
                        id
                        quantity
                      }
                    }
                  }
                }
              }
            }
          }
        }`;
        $("#searchProduct").devbridgeAutocomplete({
          serviceUrl: `https://landscape.aimsarik.com/gql/`,
          type: "POST",
          ajaxSettings: {
            headers: {
              // jwt token
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            contentType: "application/json",
            data: JSON.stringify({ query: gql }),
          },
          transformResult: function (response) {
            // convert response to json object
            response = JSON.parse(response);
            // console.log(response);
            if (response.data.autoCategories.edges.length > 0) {
              let products = [];
              response.data.autoCategories.edges.forEach((item) => {
                item.node.productSet.edges.forEach((product) => {
                  products.push({
                    id: product.node.id,
                    value: `${product.node.name} - ${product.node.code}`,
                    title: product.node.name,
                    uom: product.node.uom,
                    quantity: product.node.latestproductquantitySet[0].quantity,
                    price: aim.currencyFormat(
                      product.node.price,
                      product.node.currency.shortName,
                      2
                    ),
                    specialPrice: aim.currencyFormat(
                      product.node.specialPrice,
                      product.node.currency.shortName,
                      2
                    ),
                  });
                });
              });
              return { suggestions: products };
            }
          },
          onSelect: function (suggestion) {
            let stockID = $(`.stockItemId[value="${suggestion.id}"]`).length;
            if (stockID === 0) {
              $("#stock_out_requirements").append(`
                <tr>
                  <td>
                    <button type="button" class="btn btn-danger del_X">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                  <td>
                  <input type="hidden" class="stockItemId" name="stockItemId[]" value="${suggestion.id}" />
                    ${suggestion.title}
                  </td>
                  <td>${suggestion.uom}</td>
                  <td>
                    <span class="qty">${suggestion.quantity}</span>
                    <input type="hidden" class="form-control" name="lastQuantity[]" value="${suggestion.quantity}" />
                  </td>
                  <td>${suggestion.price}</td>
                  <td>${suggestion.specialPrice}</td>
                  <td>
                    <input
                      type="number"
                      name="addQuantity[]"
                      class="form-control"
                      required
                    />
                  </td>
                </tr>
              `);
            }
          },
        });
        $(this).focus();
      });
      $("table").on("click", ".del_X", function () {
        $(this).closest("tr").remove();
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បន្ថែមចំនួនផលិតផល</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  {/* supplierCode */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">លេខកូដនាំចូល</span>
                    </div>
                    <input
                      id="supplierCode"
                      name="supplierCode"
                      className="form-control"
                      required
                    />
                  </div>
                  {/* find product */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">រកផលិតផល</span>
                    </div>
                    <input
                      id="searchProduct"
                      name="searchProduct"
                      className="form-control"
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                  {/* detail */}
                  <div className="row mt-4 w-100">
                    <div id="no-more-tables" className="w-100">
                      <div className="card border-primary">
                        <h3 className="card-header bg-primary text-white text-center">
                          <span>បន្ថែមផលិតផល</span>
                        </h3>
                        <div className="card-body mt-2 row">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>លុប</th>
                                <th>ឈ្មោះផលិតផល</th>
                                <th>ឯកតា</th>
                                <th className="text-danger font-weight-bold">
                                  បរិមាណក្នុងស្តុក
                                </th>
                                <th>តម្លៃលក់</th>
                                <th>តម្លៃលក់ ពិសេស</th>
                                <th>បរិមាណបន្ថែម</th>
                              </tr>
                            </thead>
                            <tbody id="stock_out_requirements" />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/stock")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
