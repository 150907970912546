import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import { useNavigate } from "react-router-dom";

export default function Post(props) {
  console.log("users add");
  const navigate = useNavigate();
  const $ = window.$;
  const initialized = useRef(false);
  const business = aim.useGetBusiness();
  useEffect(() => {
    console.log("useEffect users add");
    if (!initialized.current) {
      initialized.current = true;
      // console.log("useEffect");
      props.props.validate();
      props.props.birthday();
    }
    $(".phone").select2({
      tags: true,
    });
    if (business) {
      const businessSelect = document.getElementById("business");
      businessSelect.innerHTML = "";
      // add option to select
      business.forEach((element) => {
        // const option = document.createElement("option");
        // option.value = element.node.id;
        // option.text = element.node.name;
        element.node.businessSet.edges.forEach((v) => {
          const option = document.createElement("option");
          option.value = v.node.id;
          option.text = v.node.business + " - " + element.node.name;
          businessSelect.appendChild(option);
        });
      });
    }
    $(".business").select2();
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានអ្នកប្រើប្រាស់</h2>
            </div>
            <form
              method="post"
              className="form col-12 p-0 center-block mt-4"
              id="addDataForm"
              name="addDataForm"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div
                    className="input-group mb-4 card-default col"
                    id="phone_parent"
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">លេខទូរស័ព្ទ</span>
                    </div>
                    <select
                      className="form-control phone select2"
                      multiple="multiple"
                      name="phone[]"
                      id="phone"
                    ></select>
                  </div>
                  {/* <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">លេខទូរស័ព្ទ</span>
                    </div>
                    <input
                      className="form-control"
                      name="phone"
                      id="phone"
                      type="text"
                      autoComplete="off"
                      placeholder="0123456789, 0987654321"
                      required
                    />
                  </div> */}
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អ៊ីម៉ែល</span>
                    </div>
                    <input
                      className="form-control"
                      name="email"
                      id="email"
                      type="email"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">នាមត្រកូល</span>
                    </div>
                    <input
                      className="form-control"
                      name="lastname"
                      id="lastname"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">នាមខ្លួន</span>
                    </div>
                    <input
                      className="form-control"
                      name="firstname"
                      id="firstname"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ពាក្យសម្ងាត់</span>
                    </div>
                    <input
                      className="form-control"
                      name="password"
                      id="password"
                      type="password"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">បញ្ជាក់</span>
                    </div>
                    <input
                      className="form-control"
                      name="confirm_password"
                      id="confirm_password"
                      type="password"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
                <div className="row col-12 input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ថ្ងៃកំណើត</span>
                  </div>
                  <input
                    type="text"
                    name="birthday"
                    id="birthday"
                    className="form-control"
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ភេទ</span>
                  </div>
                  <select
                    className="form-control"
                    name="gender"
                    id="gender"
                    required
                  >
                    <option value="M">ប្រុស</option>
                    <option value="F">ស្រី</option>
                    <option value="O">មិនស្គាល់</option>
                  </select>
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">តួនាទី</span>
                  </div>
                  <input
                    className="form-control"
                    name="position"
                    id="position"
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាស័យដ្ឋាន</span>
                  </div>
                  <input
                    className="form-control"
                    name="address"
                    id="address"
                    type="text"
                    autoComplete="off"
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ស្ថានភាពអ្នកប្រើ</span>
                  </div>
                  <select
                    className="form-control"
                    name="active"
                    id="active"
                    required
                  >
                    <option value="1">ប្រើប្រាស់</option>
                    <option value="0">មិនប្រើប្រាស់</option>
                  </select>
                </div>
                <div className="input-group mb-4 card-default">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ជ្រើសរើសអាជីវកម្ម</span>
                  </div>
                  <select
                    className="form-control business select2"
                    multiple="multiple"
                    name="business[]"
                    id="business"
                  ></select>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/users")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
