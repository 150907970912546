// step 1
// 1. update side bar
// 2. update the page in App.js
// step 3
import * as aim from "../../js/function.easy7d";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

// step 2
export default function TgInfo() {
  console.log("TgInfo");
  // step 4
  const navigate = useNavigate();
  const page_name = "telegram";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  // step 5
  const thead = aim.datatableHeadFoot(
    ["លរ", "ព័ត៌មាន", "ក្រុម", "សកម្មភាព"],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    console.log("useEffect TgInfo");
    aim.loadData(
      {
        tbId: tbId,
        page_name: page_name,
        load_page: "allTelegramChatGroup",
        gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                chatId
                groupName
                Telegram {
                  id
                  botName
                }
                FunctionTg {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
        }`,
      },
      {
        page_name: page_name,
        function_load: `${page_name}_datatable`,
        sumAmount: sumAmount,
        order_false: [2],
        width_column: {
          1: "100px",
        },
        columnHidden: [],
        orderColumn: ["id", "name", "", ""],
        searching: ["name_Icontains"],
        /* otherOption: {
          status: true,
        }, */
        // function
        data_execute: (result, load_page) => {
          // step 6
          // console.log("result:", result, "load_page:", load_page);
          // console.log("page_name", page_name);
          const data_tb_row = [];
          result.recordsTotal = result.data[load_page].totalCount;
          result.recordsFiltered = result.data[load_page].totalCount;
          // console.log(result.recordsTotal);
          // console.log("result:", result.data[load_page].edges);
          // forEach
          let number = 1;
          result.data[load_page].edges.forEach((v) => {
            const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
            // console.log(v.node.FunctionTg.edges);
            // loop function
            let function_name = "";
            v.node.FunctionTg.edges.forEach((element) => {
              // add comma if not first element and break line if more than 3
              if (function_name !== "") {
                function_name += ", ";
              }
              if (function_name.split(", ").length > 4) {
                function_name += "<br>";
              }
              function_name += element.node.name;
            });
            var tb_row = [
              `${number++}`,
              `Chat id: ${v.node.chatId}<br>
              ឈ្មោះBot: ${v.node.Telegram.botName}<br>
              ឈ្មោះ​ក្រុម: ${v.node.groupName}<br>
              `,
              `${function_name}`,
              `${action}`,
            ];
            data_tb_row.push(tb_row);
          });
          return data_tb_row;
        },
      }
    );
    document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
      const el = e.target;
      if (el.classList.contains("editData")) {
        const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
        const { id, page } = data_edit;
        navigate(`/post/${page}?action=edit&id=${id}`);
      }
    });
  }, [page_name, tbId, sumAmount, navigate]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
