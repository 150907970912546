// step 3
import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
// step 1
export default function Post(props) {
  console.log("company Edit");
  // step 2
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  // step 4
  const navigate = useNavigate();
  const nameRef = useRef();
  const branchRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const addressRef = useRef();
  const conditionsRef = useRef();
  useEffect(() => {
    console.log("useEffect company edit");
    // get data
    const query = `
      query {
        company(id: "${id}") {
          name
          branch
          phone
          email
          address
          conditions
        }
      }
    `;
    const variables = {
      id,
    };
    const callback = (data) => {
      const d = data.data.company;
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
          confirmButtonText: "យល់ព្រម",
        });
        navigate("/post/coa?action=add");
      } else {
        // console.log("data:", d);
        nameRef.current.value = d.name;
        branchRef.current.value = d.branch;
        phoneRef.current.value = d.phone;
        emailRef.current.value = d.email;
        addressRef.current.value = d.address;
        conditionsRef.current.value = d.conditions;
      }
    };
    aim.jquery_free(query, variables, callback);
  }, [id, navigate]);

  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានក្រុមហ៊ុន</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* company name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះក្រុមហ៊ុន</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    required
                    ref={nameRef}
                  />
                </div>
                {/* branch */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">សាខា</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="branch"
                    id="branch"
                    autoComplete="off"
                    required
                    ref={branchRef}
                  />
                </div>
                {/* logo */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">និមិត្តសញ្ញា</span>
                  </div>
                  <input
                    className="form-control"
                    type="file"
                    name="logo"
                    id="logo"
                    autoComplete="off"
                  />
                </div>
                {/* company phone */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខទូរសព្ទ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    required
                    ref={phoneRef}
                  />
                </div>
                {/* company email */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អ៊ីមែល</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    ref={emailRef}
                  />
                </div>
                {/* company address */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាសយដ្ឋាន</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="off"
                    required
                    ref={addressRef}
                  />
                </div>
                {/* conditions */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លក្ខខណ្ឌ</span>
                  </div>
                  {/* texearea */}
                  <textarea
                    className="form-control"
                    name="conditions"
                    id="conditions"
                    rows="5"
                    required
                    ref={conditionsRef}
                  ></textarea>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/company")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
